import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseurlService } from 'src/app/services/functions/baseurl/baseurl.service';
import { ConsoleService } from 'src/app/services/functions/console/console.service';
import { DomainService } from 'src/app/services/functions/domain/domain.service';
import { Environment } from 'src/environments/environment';

@Component({
  selector: 'app-wo-ex-portfolio',
  templateUrl: './wo-ex-portfolio.component.html',
  styleUrls: ['./wo-ex-portfolio.component.css']
})
export class WoExPortfolioComponent implements OnInit {
  env: any = Environment;
  debug: string = this.env.app.debug;
  title: string = this.env.app.title;

  domainInfo: string = '';
  domCoreExt: string = '';
  domainPtcl: string = '';
  urlFront: string = '';

  dtXamppSeven: any[] = [];
  dtXamppEight: any[] = [];
  dtWorkSpace: any[] = [];

  constructor (
    public router: Router,
    public srvBaseUrl: BaseurlService,
    public srvConsole: ConsoleService,
    public srvDomain: DomainService,
  ) {}

  ngOnInit(): void {
    this.setComponent();
    this.getDomEnv();
    this.getDomain();
    this.getCoreExt();
    this.getUrlHref();

    this.jsXamppSeven();
    this.jsXamppEight();
    this.jsWorkSpace();
  }

  setComponent() {
    // Obtener el nombre del componente automaticamente
    let information = this.constructor.name;
    let finalData = information.replace('Component', '');
    let message = `Current Component: ${finalData}`;
    this.debug && this.srvConsole.print('warn', message);
  }

  getDomEnv() {
    let domainCheck = this.srvDomain.getDomainVerify();
    let mssgVerify = `enviroment check: ${domainCheck}`;
    this.debug && this.srvConsole.print('info', mssgVerify);
  }

  getDomain() {
    // Obtener el dominio completo de la url
    this.domainInfo = this.srvDomain.getDomainInfo();
    let messageInfo = `Domain URL: ${this.domainInfo}`;
    this.debug && this.srvConsole.print('info', messageInfo);
  }

  getCoreExt() {
    // Obtener unicamente el dominio actual
    let domainCore = this.srvDomain.getDomainCore();
    let domainExt = this.srvDomain.getDomainExt();
    this.domCoreExt = `${domainCore}.${domainExt}`;
    let messageCore = `Core Ext: ${this.domCoreExt}`;
    this.debug && this.srvConsole.print('info', messageCore);
  }

  getUrlHref() {
    let urlDomain = this.domainInfo;
    let urlBase = this.srvBaseUrl.getBaseUrl();
    if (urlBase === '/') { urlBase = ''; }
    this.urlFront = urlDomain + urlBase;
    let message = `URL Front: ${this.urlFront}`;
    this.debug && this.srvConsole.print('info', message);
  }

  jsXamppSeven() {
    this.dtXamppSeven = [
      {
        'id_register': '1',
        'cd_status': 'active',
        'os_demo': `https://xsp.${this.domCoreExt}/prisma_alfaparf_ocrend/`,
        'os_link': 'https://github.com/adisonzenemij/prisma_alfaparf_ocrend',
        'os_privacy': 'Privado',
        'os_repository': 'GitHub',
        'os_title': 'Prisma Ocrend Alfaparf',
      },
      {
        'id_register': '2',
        'cd_status': 'active',
        'os_demo': `https://xsp.${this.domCoreExt}/prisma_internal_ocrend/`,
        'os_link': 'https://github.com/adisonzenemij/prisma_internal_ocrend',
        'os_privacy': 'Privado',
        'os_repository': 'GitHub',
        'os_title': 'Prisma Ocrend Internal',
      },
      {
        'id_register': '3',
        'cd_status': 'active',
        'os_demo': `https://xsp.${this.domCoreExt}/prisma_service_ocrend/`,
        'os_link': 'https://github.com/adisonzenemij/prisma_service_ocrend',
        'os_privacy': 'Privado',
        'os_repository': 'GitHub',
        'os_title': 'Prisma Ocrend Service',
      },
      {
        'id_register': '4',
        'cd_status': 'active',
        'os_demo': `https://xsp.${this.domCoreExt}/prisma_webapp_ocrend/`,
        'os_link': 'https://github.com/adisonzenemij/prisma_webapp_ocrend',
        'os_privacy': 'Privado',
        'os_repository': 'GitHub',
        'os_title': 'Prisma Ocrend Webapp',
      },
      {
        'id_register': '5',
        'cd_status': 'block',
        'os_demo': `https://xsp.${this.domCoreExt}/prisma_webbck_ocrend/`,
        'os_link': 'https://github.com/adisonzenemij/prisma_webbck_ocrend',
        'os_privacy': 'Privado',
        'os_repository': 'GitHub',
        'os_title': 'Prisma Ocrend Webbck',
      },
      {
        'id_register': '6',
        'cd_status': 'active',
        'os_demo': `https://xsp.${this.domCoreExt}/prisma_worked_ocrend/`,
        'os_link': 'https://github.com/adisonzenemij/prisma_worked_ocrend',
        'os_privacy': 'Privado',
        'os_repository': 'GitHub',
        'os_title': 'Prisma Ocrend Worked',
      },
      {
        'id_register': '7',
        'cd_status': 'active',
        'os_demo': `https://xsp.${this.domCoreExt}/staff_medimple_ocrend/`,
        'os_link': 'https://github.com/adisonzenemij/staff_medimple_ocrend',
        'os_privacy': 'Privado',
        'os_repository': 'GitHub',
        'os_title': 'Staff Ocrend Medmiple',
      },
      {
        'id_register': '8',
        'cd_status': 'active',
        'os_demo': `https://xsp.${this.domCoreExt}/sena_radicated_slim/`,
        'os_link': 'https://github.com/engsoftsena/sena_radicated_slim',
        'os_privacy': 'Publico',
        'os_repository': 'GitHub',
        'os_title': 'Sena Project Slim',
      },
      {
        'id_register': '9',
        'cd_status': 'active',
        'os_demo': `https://xsp.${this.domCoreExt}/staff_slim_seven/`,
        'os_link': 'https://github.com/adisonzenemij/staff_slim_seven',
        'os_privacy': 'Privado',
        'os_repository': 'GitHub',
        'os_title': 'Staff Slim Seven',
      },
    ];
    this.dtXamppSeven.sort((a, b) => parseInt(b.id_register) - parseInt(a.id_register));
    console.log(this.dtXamppSeven);
  }

  jsXamppEight() {
    this.dtXamppEight = [
      {
        'id_register': '3',
        'cd_status': 'active',
        'os_demo': `https://xep.${this.domCoreExt}/staff_slim_eight/`,
        'os_link': 'https://github.com/adisonzenemij/staff_slim_eight',
        'os_privacy': 'Privado',
        'os_repository': 'GitHub',
        'os_title': 'Staff Slim Eight',
      },
      {
        'id_register': '4',
        'cd_status': 'block',
        'os_demo': `https://xep.${this.domCoreExt}/staff_laravel_project/`,
        'os_link': 'https://github.com/adisonzenemij/staff_laravel_project',
        'os_privacy': 'Publico',
        'os_repository': 'GitHub',
        'os_title': 'Staff Laravel Project',
      },
    ];
    this.dtXamppEight.sort((a, b) => parseInt(b.id_register) - parseInt(a.id_register));
    console.log(this.dtXamppEight);
  }

  jsWorkSpace() {
    this.dtWorkSpace = [
      {
        'id_register': '1',
        'cd_status': 'active',
        'os_demo': `https://iisp.${this.domCoreExt}/sena_radicated_angular/`,
        'os_link': 'https://github.com/engsoftsena/sena_radicated_angular',
        'os_privacy': 'Publico',
        'os_repository': 'GitHub',
        'os_title': 'Sena Project Angular',
      },
    ];
    this.dtWorkSpace.sort((a, b) => parseInt(b.id_register) - parseInt(a.id_register));
    console.log(this.dtWorkSpace);
  }
}
