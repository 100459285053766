import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { JsReadService } from 'src/app/services/apis/java/spring/js-read/js-read.service';

@Injectable({
  providedIn: 'root'
})
export class TgUserDataService {

  constructor(
    private jsRead: JsReadService,
  ) { }

  // JS: Java - Spring - get/all
  jsGetAll(): Observable<any> {
    return this.jsRead.getAll(
      'tgUserData'
    );
  }

  // JS: Java - Spring - page/all
  jsGetPage(): Observable<any> {
    return this.jsRead.getPage(
      'tgUserData'
    );
  }
}
