import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from './guards/auth/auth.guard';

import { WoExternalComponent } from './working/wo-external/wo-external.component';
import { WoExApplicationComponent } from './working/wo-external/wo-ex-application/wo-ex-application.component';
import { WoExLandingComponent } from './working/wo-external/wo-ex-landing/wo-ex-landing.component';
import { WoExLoginComponent } from './working/wo-external/wo-ex-login/wo-ex-login.component';
import { WoExPaypalComponent } from './working/wo-external/wo-ex-paypal/wo-ex-paypal.component';
import { WoExPortfolioComponent } from './working/wo-external/wo-ex-portfolio/wo-ex-portfolio.component';
import { WoExRegisterComponent } from './working/wo-external/wo-ex-register/wo-ex-register.component';
import { WoExStudyComponent } from './working/wo-external/wo-ex-study/wo-ex-study.component';

import { WoTechnologyComponent } from './working/wo-technology/wo-technology.component';
import { WoTeRoleActionComponent } from './working/wo-technology/wo-te-role-action/wo-te-role-action.component';
import { WoTeRoleAuthComponent } from './working/wo-technology/wo-te-role-auth/wo-te-role-auth.component';
import { WoTeRoleDataComponent } from './working/wo-technology/wo-te-role-data/wo-te-role-data.component';
import { WoTeRoleFuncComponent } from './working/wo-technology/wo-te-role-func/wo-te-role-func.component';
import { WoTeRoleGroupComponent } from './working/wo-technology/wo-te-role-group/wo-te-role-group.component';
import { WoTeRolePermitComponent } from './working/wo-technology/wo-te-role-permit/wo-te-role-permit.component';
import { WoTeUserDataComponent } from './working/wo-technology/wo-te-user-data/wo-te-user-data.component';
import { WoTeUserEmailComponent } from './working/wo-technology/wo-te-user-email/wo-te-user-email.component';
import { WoTeUserIpComponent } from './working/wo-technology/wo-te-user-ip/wo-te-user-ip.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'external/landing' },
  {
    path: 'external', component: WoExternalComponent,
    children: [
      { path: 'application', component: WoExApplicationComponent },
      { path: 'landing', component: WoExLandingComponent },
      { path: 'login', component: WoExLoginComponent },
      { path: 'paypal', component: WoExPaypalComponent },
      { path: 'portfolio', component: WoExPortfolioComponent },
      { path: 'register', component: WoExRegisterComponent },
      { path: 'study', component: WoExStudyComponent },
    ],
  },
  {
    path: 'technology',
    canActivate: [authGuard],
    component: WoTechnologyComponent,
    children: [
      { path: 'role',
        children: [
          { path: 'action', component: WoTeRoleActionComponent },
          { path: 'auth', component: WoTeRoleAuthComponent },
          { path: 'data', component: WoTeRoleDataComponent },
          { path: 'func', component: WoTeRoleFuncComponent },
          { path: 'group', component: WoTeRoleGroupComponent },
          { path: 'permit', component: WoTeRolePermitComponent },
        ],
      },
      { path: 'user',
        children: [
          { path: 'data', component: WoTeUserDataComponent },
          { path: 'email', component: WoTeUserEmailComponent },
          { path: 'ip', component: WoTeUserIpComponent },
        ],
      },
    ],
  },
  { path: '**', redirectTo: 'external/landing' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
