import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ConsoleService } from '../console/console.service';
import { StorageService } from '../storage/storage.service';
import { Environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private strgAuth = Environment.strg.auth;

  constructor(
    private serviceConsole: ConsoleService,
    private serviceStorage: StorageService,
  ) { }

  getToken(): any {
    let data = this.serviceStorage.sessionGet(this.strgAuth);
    if (data === null) { return null; }

    try {
      const parsedData = JSON.parse(data);

      // Verificar si el token tiene el formato esperado
      if (parsedData && typeof parsedData.token === 'string') {
        this.serviceConsole.sccs(
          `Token: ${parsedData.token}`
        );
        return parsedData;
      } else {
        this.serviceConsole.error(
          `Token: Formato Incorrecto`
        );
        return null;
      }
    } catch (resp) {
      this.serviceConsole.error(
        `Token: Formato Invalido`
      );
      return null;
    }
  }

  getBearer(): HttpHeaders | null {
    let strg: any = this.getToken();

    const token = strg?.token;
    let headers = new HttpHeaders().set(
      'Content-Type',
      'application/json'
    );

    if (token !== null) {
      headers = headers.set(
        'Authorization',
        `Bearer ${token}`
      );
    }

    if (token === null) {
      return null;
    }

    return headers;
  }
}
