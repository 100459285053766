import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, tap, throwError } from 'rxjs';
import { Environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class JsCheckService {
  private endpoint = Environment.api.java.spring.test;

  constructor(
    private http: HttpClient,
  ) { }

  available(): Observable<any> {
    console.warn('available');
    let url = this.endpoint;
    let api = `pub/check/verify`;
    return this.http.get<any>(`${url}/${api}`).pipe(
      catchError((e) => {
        console.log('error', e);
        return throwError(() => e);
      }),
      tap((response: any) => {
        console.log('response', response);
      }),
    );
  }
}
