import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  mode(
    param: 'local' | 'session',
    action: 'get' | 'json' | 'item' | 'remove',
    name: string, data?: any
  ) {
    // Obtener el método correspondiente según el tipo de almacenamiento
    const storage = param === 'local' ? this.lcMethod() : this.snMethod();

    // Crear el mapa de acciones
    const actions = new Map([
      ['get', () => storage.get(name)],
      ['json', () => data !== undefined && storage.json(name, data)],
      ['item', () => data !== undefined && storage.item(name, data)],
      ['remove', () => storage.remove(name)]
    ]);

    // Obtener la función correspondiente del mapa de acciones y ejecutarla
    const actionFunc = actions.get(action);
    if (actionFunc) {
      return actionFunc();
    } else {
      throw new Error('Acción no válida');
    }
  }

  private lcMethod() {
    return {
      get: this.lcGet,
      json: this.lcJson,
      item: this.lcItem,
      remove: this.lcRemove
    };
  }

  private snMethod() {
    return {
      get: this.snGet,
      json: this.snJson,
      item: this.snItem,
      remove: this.snRemove
    };
  }

  // Obtener datos del almacenamiento local
  private lcGet(name: any): any {
    return localStorage.getItem(
      name
    );
  }

  // Adicionar datos en el almacenamiento local
  private lcJson(name: any, data: any) {
    let result = JSON.stringify(data);
    localStorage.setItem(
      name, result
    );
  }

  // Adicionar datos en el almacenamiento local
  private lcItem(name: any, data: any) {
    localStorage.setItem(
      name, data
    );
  }

  // Remover datos del almacenamiento local
  private lcRemove(name: any) {
    localStorage.removeItem(
      name
    );
  }

  // Obtener datos del almacenamiento de sesión
  private snGet(name: any) {
    return sessionStorage.getItem(
      name
    );
  }

  // Adicionar datos en el almacenamiento de sesión
  private snJson(name: any, data: any) {
    let result = JSON.stringify(data);
    sessionStorage.setItem(
      name, result
    );
  }

  // Adicionar datos en el almacenamiento de sesión
  private snItem(name: any, data: any) {
    sessionStorage.setItem(
      name, data
    );
  }

  // Remover datos del almacenamiento de sesión
  private snRemove(name: any)  {
    sessionStorage.removeItem(
      name
    );
  }
}
