import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';

import { Environment } from 'src/environments/environment';
import { BaseurlService } from 'src/app/services/functions/baseurl/baseurl.service';
import { DomainService } from 'src/app/services/functions/domain/domain.service';

@Component({
  selector: 'app-wo-external',
  templateUrl: './wo-external.component.html',
  styleUrls: ['./wo-external.component.css']
})
export class WoExternalComponent implements OnInit {
  appTitle: string = Environment.title;
  domainMajor: string = '';
  urlFront: string = '';

  codeLink: string[] = [];
  codeScript: string[] = [];
  geeksData: any[] = [];
  geeksJson: string = 'assets/geeks/json';
  geeksFile: string = 'data.json';
  geeksUrl: string = '';

  constructor (
    private renderer: Renderer2,
    private router: Router,
    private serviceBaseUrl: BaseurlService,
    private serviceDomain: DomainService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.getDomain();
    this.getUrlHref();

    this.urlGeeks();
    await this.jsonGeeks();
    this.idxHeadFilter();
    this.idxHeadLink();
    this.idxScriptFilter();
    this.idxScriptLink();
  }

  getDomain() {
    let dmMajor = this.serviceDomain.getDomainInfo();
    this.domainMajor = dmMajor;
  }

  getUrlHref() {
    let urlDomain = this.domainMajor;
    let urlBase = this.serviceBaseUrl.getBaseUrl();
    if (urlBase === '/') { urlBase = ''; }
    this.urlFront = urlDomain + urlBase;
    //console.log(`urlFront: ${this.urlFront}`);
  }

  urlGeeks() {
    this.geeksUrl = `${this.geeksJson}/${this.geeksFile}`;
  }

  async jsonGeeks() {
    let urlData = `${this.urlFront}/${this.geeksUrl}`;
    const dataFetch = await this.dataFetch(urlData);
    if (dataFetch && dataFetch.length > 0) {
      console.log('dataFetch', dataFetch);
      this.geeksData = dataFetch;
    }
  }

  async dataFetch(url: any) {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(
          `HTTP error! status: ${response.status}`
        );
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching the JSON data:', error);
      return null;
    }
  }

  idxHeadFilter() {
    this.codeLink.push('s4iwr4THepL9radROr4M');
  }

  idxHeadLink() {
    this.geeksData.filter(
      item => item.type === 'link' &&
      this.codeLink.includes(item.code)
    ).forEach(item => {
      const linkElement = this.renderer.createElement('link');
      this.renderer.setAttribute(linkElement, 'rel', 'stylesheet');
      this.renderer.setAttribute(linkElement, 'href', item.link);
      this.renderer.appendChild(document.head, linkElement);
    });
  }

  idxScriptFilter() {
    this.codeScript.push('wlWL1RaSop2yejesoqeb');
    this.codeScript.push('M9TL1e2rLwagiFrE4h3j');
    this.codeScript.push('weteTuB1uprewravEQuB');
    this.codeScript.push('vewrewr127guxUyaZlxA');
  }

  idxScriptLink() {
    this.geeksData.filter(
      item => item.type === 'script' &&
      this.codeScript.includes(item.code)
    ).forEach(item => {
      const scriptElement = this.renderer.createElement('script');
      this.renderer.setAttribute(scriptElement, 'src', item.link);
      this.renderer.appendChild(document.body, scriptElement);
    });
  }
}
