import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ConsoleService } from '../console/console.service';
import { StorageService } from '../storage/storage.service';
import { Environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public strgAuth = Environment.strg.auth;

  constructor(
    public srvConsole: ConsoleService,
    public srvStorage: StorageService,
  ) { }

  getToken(): any {
    let data = this.srvStorage.mode(
      'local', 'get', this.strgAuth
    );
    if (data === null) { return null; }

    try {
      const parsedData = JSON.parse(data);

      // Verificar existencia del token en su formato esperado
      if (parsedData && typeof parsedData.token === 'string') {
        let message = `Token: ${parsedData.token}`
        this.srvConsole.print('sccs', message);
        return parsedData;
      } else {
        let message = `Token: Formato Incorrecto`
        this.srvConsole.print('error', message);
        return null;
      }
    } catch (resp) {
      let message = `Token: Formato Invalido`
      this.srvConsole.print('error', message);
      return null;
    }
  }

  getBearer(): HttpHeaders | null {
    let strg: any = this.getToken();

    const token = strg?.token;
    let headers = new HttpHeaders().set(
      'Content-Type',
      'application/json'
    );

    if (token !== null) {
      headers = headers.set(
        'Authorization',
        `Bearer ${token}`
      );
    }

    if (token === null) {
      return null;
    }

    return headers;
  }
}
