import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DomainService {

  constructor() { }

  getWindowLoc() {
    let winLoc = window.location;
    let hash = winLoc.hash;
    let host = winLoc.host;
    let hstn = winLoc.hostname;
    let href = winLoc.href;
    let origin = winLoc.origin;
    let path = winLoc.pathname;
    let port = winLoc.port;
    let ptcl = winLoc.protocol;
    let search = winLoc.search;
    return {
      hash,
      host,
      hstn,
      href,
      origin,
      path,
      port,
      ptcl,
      search,
    };
  }

  getDomainVerify(winLoc: any = '') {
    const { hstn, } = this.getWindowLoc();
    // Verificar entorno de trabajo localhost
    if (hstn.includes('localhost')) {
      console.log('environment: localhost');
      winLoc = hstn;
    }
    // Verificar que no tenga subdominio y no es localhost
    if (hstn.split('.').length <= 2 &&
      !hstn.includes('localhost')
    ) {
      console.log('environment: production');
      // Redirigir a la versión con www
      window.location.href = `https://www.${hstn}`;
    }
    return winLoc;
  }

  getDomainInfo(result: any = '') {
    const { hstn, origin } = this.getWindowLoc();
    // Verificar entorno de trabajo development
    if (hstn.includes('localhost')) { result = origin; }
    // Verificar entorno de trabajo production
    if (!hstn.includes('localhost')) {
      let winPtcl = this.getDomainPtcls();
      let winSub = this.getDomainSubd();
      let winMain = this.getDomainCore();
      let winExt = this.getDomainExt();
      let winCtry = this.getDomainCtry();
      let valCtry = (winCtry && winCtry.trim() !== '') ? `.${winCtry}` : '';
      result = `${winPtcl}//${winSub}.${winMain}.${winExt}${valCtry}`;
    }
    return result;
  }

  // Obtener Protocolo
  getDomainPtcls() {
    const { ptcl } = this.getWindowLoc();
    return ptcl;
  }

  // Obtener Subdominio
  getDomainSubd() {
    const { hstn } = this.getWindowLoc();
    const parts = hstn.split('.');
    return parts.length > 0 ? parts[0] : '';
  }

  // Obtener Dominio
  getDomainCore() {
    const { hstn } = this.getWindowLoc();
    const parts = hstn.split('.');
    return parts.length > 1 ? parts[1] : '';
  }

  // Obtener Extensión del Dominio
  getDomainExt() {
    const { hstn } = this.getWindowLoc();
    const parts = hstn.split('.');
    return parts.length > 2 ? parts[2] : '';
  }

  // Obtener País (Extensión del dominio)
  getDomainCtry() {
    const { hstn } = this.getWindowLoc();
    const parts = hstn.split('.');
    return parts.length > 3 ? parts[3] : '';
  }

  // Obtener Pathname
  getDomainPath() {
    const { path } = this.getWindowLoc();
    return path;
  }

  getSubOther(sub: any, endpoint: any, result: any = '') {
    const { hstn, origin } = this.getWindowLoc();
    // Verificar entorno de trabajo development
    if (hstn.includes('localhost')) { result = origin; }
    // Verificar entorno de trabajo production
    if (!hstn.includes('localhost')) {
      let winEnd = endpoint ? `/${endpoint}` : '';
      let winPtcl = this.getDomainPtcls();
      let winMain = this.getDomainCore();
      let winExt = this.getDomainExt();
      let winCtry = this.getDomainCtry();
      let valCtry = (winCtry && winCtry.trim() !== '') ? `.${winCtry}` : '';
      result = `${winPtcl}//${sub}.${winMain}.${winExt}${valCtry}${winEnd}`;
    }
    return result;
  }
}
