import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { TgUserData } from 'src/app/interfaces/modules/technology/tg-user-data';
import { JsAuthService } from 'src/app/services/apis/java/spring/js-auth/js-auth.service';
import { JsCheckService } from 'src/app/services/apis/java/spring/js-check/js-check.service';
import { AuthService } from 'src/app/services/functions/auth/auth.service';
import { BaseurlService } from 'src/app/services/functions/baseurl/baseurl.service';
import { ConsoleService } from 'src/app/services/functions/console/console.service';
import { DomainService } from 'src/app/services/functions/domain/domain.service';
import { StorageService } from 'src/app/services/functions/storage/storage.service';
import { Environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
declare let bootstrap: any;

@Component({
  selector: 'app-wo-ex-login',
  templateUrl: './wo-ex-login.component.html',
  styleUrls: ['./wo-ex-login.component.css']
})
export class WoExLoginComponent implements OnInit {
  strgAuth: string = Environment.strg.auth;

  env: any = Environment;
  debug: string = this.env.app.debug;
  title: string = this.env.app.title;

  domainInfo: string = '';
  domCoreExt: string = '';
  domainPtcl: string = '';
  urlFront: string = '';

  isLoading: boolean = false;
  formSubmit: boolean = false;
  loginForm: FormGroup;
  tgUserData: TgUserData[] = [];

  constructor (
    public fb: FormBuilder,
    public router: Router,
    public jsAuth: JsAuthService,
    public jsCheck: JsCheckService,
    public srvAuth: AuthService,
    public srvBaseUrl: BaseurlService,
    public srvConsole: ConsoleService,
    public srvDomain: DomainService,
    public srvStorage: StorageService,
  ) {
    this.loginForm = this.formLogin();
  }

  ngOnInit(): void {
    this.setComponent();
    this.getDomEnv();
    this.getDomain();
    this.getCoreExt();
    this.getUrlHref();
  }

  setComponent() {
    // Obtener el nombre del componente automaticamente
    let information = this.constructor.name;
    let finalData = information.replace('Component', '');
    let message = `Current Component: ${finalData}`;
    this.debug && this.srvConsole.print('warn', message);
  }

  getDomEnv() {
    let domainCheck = this.srvDomain.getDomainVerify();
    let mssgVerify = `enviroment check: ${domainCheck}`;
    this.debug && this.srvConsole.print('info', mssgVerify);
  }

  getDomain() {
    // Obtener el dominio completo de la url
    this.domainInfo = this.srvDomain.getDomainInfo();
    let messageInfo = `Domain URL: ${this.domainInfo}`;
    this.debug && this.srvConsole.print('info', messageInfo);
  }

  getCoreExt() {
    // Obtener unicamente el dominio actual
    let domainCore = this.srvDomain.getDomainCore();
    let domainExt = this.srvDomain.getDomainExt();
    this.domCoreExt = `${domainCore}.${domainExt}`;
    let messageCore = `Core Ext: ${this.domCoreExt}`;
    this.debug && this.srvConsole.print('info', messageCore);
  }

  getUrlHref() {
    let urlDomain = this.domainInfo;
    let urlBase = this.srvBaseUrl.getBaseUrl();
    if (urlBase === '/') { urlBase = ''; }
    this.urlFront = urlDomain + urlBase;
    let message = `URL Front: ${this.urlFront}`;
    this.debug && this.srvConsole.print('info', message);
  }

  formLogin(): FormGroup {
    return this.fb.group({
      cdLogin: ['', Validators.required],
      cdPassword: ['', Validators.required],
    });
  }

  async actionCheck(): Promise<any> {
    try {
      const response = await firstValueFrom(
        this.jsCheck.available()
      );
      console.log('Response from backend check:', response);
      // Devuelve true si el backend está disponible
      return response.status === true;
    } catch (error) {
      console.error('Error al verificar la disponibilidad del backend:', error);
      this.openModalById('modalAlertAvailable');
      // Devuelve false si hay un error o el backend no está disponible
      return false;
    }
  }

  loadingStatus(response: boolean) {
    this.isLoading = response;
  }

  elementsId(id: string) {
    return document.getElementById(id);
  }

  openModalById(modalId: string) {
    const modalElement = this.elementsId(modalId);
    if (modalElement) {
      const modalInfo = new bootstrap.Modal(
        modalElement
      );
      modalInfo.show();
    } else {
      console.error(`Modal with ID ${modalId} not found.`);
    }
  }

  async logionAction() {
    if (!this.loginForm.valid) {
      this.formSubmit = true;
      return;
    }
    this.loadingStatus(true);
    const available = await this.actionCheck();
    if (!available) { this.loadingStatus(false); }
    if (available) { this.loginProccess(); }
  }

  loginProccess() {
    const { cdLogin, cdPassword } = this.loginForm.value;

    // Crear un objeto de tipo con los datos del formulario
    const payload: TgUserData = {
      cdLogin: cdLogin,
      cdPassword: cdPassword,
    };

    this.jsAuth.login(payload).subscribe({
      next: (response: any) => {
        this.loginEnding(response);
      },
      error: (response: any) => {
        this.loginEnding(response);
      },
      complete: () => {
        console.log('request completed');
      },
    });
  }

  loginEnding(response: any) {
    console.warn('loginEnding');
    console.log('response', response);
    let dataSweet = { html: '', icon: '', title: '', };
    this.loadingStatus(false);
    if (response.status === 403) {
      dataSweet.html = 'Credenciales Incorrectas';
      dataSweet.icon = 'error';
      dataSweet.title = 'Advertencia!';
      this.messageLoading(dataSweet, response);
    }
    if (response.status === 200) {
      dataSweet.html = 'Acceso Autorizado';
      dataSweet.icon = 'success';
      dataSweet.title = 'Exito!';
      this.messageLoading(dataSweet, response);
    }
  }

  messageLoading(message: any, response: any) {
    console.warn('messageLoading');
    console.log('message', message);
    console.log('response', response);
    Swal.fire({
      allowOutsideClick: false,
      confirmButtonText: 'Entendido',
      customClass: {
        confirmButton: 'rounded-0 btn btn-md btn-success',
      },
      html: `<span class="text-dark">${message.html}</span>`,
      icon: message.icon,
      title: `<h3>${message.title}!</h3>`,
    }).then(() => {
      if (response.status === 200) {
        this.loginAuth(response);
      }
    });
  }

  loginAuth(response: any) {
    console.warn('loginAuth');
    console.log('response', response);
    console.log('body', response.body);

    let dataStrg = this.strgGet();
    if (dataStrg) { this.strgRemove(); }
    if (dataStrg) { this.strgSet(response.body); }
    if (!dataStrg) { this.strgSet(response.body); }

    let verifyStrg = this.strgGet();
    if (verifyStrg) {
      this.router.navigate(
        ['/technology/user/data']
      );
    }
  }

  // Obtener token de la autenticación
  strgGet() {
    return this.srvStorage.mode(
      'local', 'get', this.strgAuth
    );
  }

  // Asignar token de la autenticación
  strgSet(response: any) {
    this.srvStorage.mode(
      'local',
      'json',
      this.strgAuth,
      response
    );
  }

  // Remover token de la autenticación
  strgRemove() {
    this.srvStorage.mode(
      'local',
      'remove',
      this.strgAuth
    );
  }
}
