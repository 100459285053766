import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { TgUserData } from 'src/app/interfaces/modules/technology/tg-user-data';
import { Environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class JsAuthService {
  jsHost = Environment.api.java.spring.host;
  jsApp = Environment.api.java.spring.app;
  jsNet = Environment.api.java.spring.net;
  jsTest = Environment.api.java.spring.test;
  jsWork = Environment.api.java.spring.work;
  apiUrl = 'api/auth';

  constructor(
    public http: HttpClient,
  ) { }

  login(payload: TgUserData): Observable<any> {
    console.log('payload', payload);
    let url = `${this.jsHost}/${this.apiUrl}`;
    let endpoint = `${url}/json`;
    return this.http.post<any>(
      endpoint, payload,
      { observe: 'response' }
    ).pipe(
      catchError((response) => {
        console.error('response', response);
        return throwError(() => response);
      })
    );
  }
}
