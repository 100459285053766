<main>
  <!-- section -->
  <section class="position-relative nmLanding">
    <img src="{{ urlFront }}/assets/works/external/img/landing/jpg/developer-software-main.jpg"
    alt="Background Image" class="img-fluid nmBackImage" />
    <div class="container my-lg-8 position-relative nmContainer nmBackContent">
      <div class="row align-items-center">
        <div class="col-12 mb-lg-0 mx-auto">
          <span class="display-1 fs-1 fw-bold mb-3 text-dark">
            Adison Jimenez
          </span>
          <h1 class="display-3 fs-2 fw-bold mb-3 text-dark">
            <span>Desarrollador de Software</span>
          </h1>
        </div>
        <div class="col-12 mb-8"></div>
        <div class="col-lg-6 mb-6 mb-lg-0 mx-auto d-none">
          <div class="text-center">
            <h1 class="display-3 fw-bold mb-3 text-dark">
              <span>Desarrollador de Software</span>
            </h1>
            <h4 class="pe-lg-10 mb-5 text-dark">
              Desarrollador de software con sólidos conocimientos en programación y en infraestructura.
              Me especializo en combinar la creación de soluciones tecnológicas innovadoras
              con una sólida base en la construcción y gestión de la infraestructura necesaria.
              Estoy preparado para enfrentar desafíos y contribuir al éxito en el dinámico mercado tecnológico.
            </h4>
            <h5 class="text-dark mb-4">
              <i class="fe fe-check icon-xxs icon-shape bg-success text-white rounded-circle me-2"></i>
              <span>Contribucion al éxito en el mercado tecnológico dinámico.</span>
            </h5>
            <h5 class="text-dark mb-4">
              <i class="fe fe-check icon-xxs icon-shape bg-success text-white rounded-circle me-2"></i>
              <span>Creación y gestión de soluciones tecnológicas integrales.</span>
            </h5>
            <h5 class="text-dark mb-4">
              <i class="fe fe-check icon-xxs icon-shape bg-success text-white rounded-circle me-2"></i>
              <span>Enfrentamiento de desafíos con soluciones tecnológicas</span>
            </h5>
            <h5 class="text-dark mb-4">
              <i class="fe fe-check icon-xxs icon-shape bg-success text-white rounded-circle me-2"></i>
              <span>Transformación de ideas en soluciones tecnológicas.</span>
            </h5>
            <h5 class="text-dark mb-4">
              <i class="fe fe-check icon-xxs icon-shape bg-success text-white rounded-circle me-2"></i>
              <span>Construcción y gestión en infraestructuras de manera eficiente.</span>
            </h5>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
