import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseurlService } from 'src/app/services/functions/baseurl/baseurl.service';
import { ConsoleService } from 'src/app/services/functions/console/console.service';
import { DomainService } from 'src/app/services/functions/domain/domain.service';
import { Environment } from 'src/environments/environment';

@Component({
  selector: 'app-ge-in-header',
  templateUrl: './ge-in-header.component.html',
  styleUrls: ['./ge-in-header.component.css']
})
export class GeInHeaderComponent implements OnInit {
  env: any = Environment;
  debug: string = this.env.app.debug;
  title: string = this.env.app.title;

  domainMajor: string = '';
  urlFront: string = '';

  constructor (
    public router: Router,
    public srvBaseUrl: BaseurlService,
    public srvConsole: ConsoleService,
    public srvDomain: DomainService,
  ) {}

  ngOnInit(): void {
    this.getDomain();
    this.getUrlHref();
    //this.getSubDmn();
  }

  getDomain() {
    let dmMajor = this.srvDomain.getDomainInfo();
    this.domainMajor = dmMajor;
  }

  getUrlHref() {
    let urlDomain = this.domainMajor;
    let urlBase = this.srvBaseUrl.getBaseUrl();
    if (urlBase === '/') { urlBase = ''; }
    this.urlFront = urlDomain + urlBase;
    //console.log(`urlFront: ${this.urlFront}`);
  }
}
