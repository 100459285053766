import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { GeExFooterComponent } from './geeks/ge-external/ge-ex-footer/ge-ex-footer.component';
import { GeExNavbarComponent } from './geeks/ge-external/ge-ex-navbar/ge-ex-navbar.component';
import { GeExProfileComponent } from './geeks/ge-external/ge-ex-profile/ge-ex-profile.component';

import { GeInHeaderComponent } from './geeks/ge-internal/ge-in-header/ge-in-header.component';
import { GeInNavbarComponent } from './geeks/ge-internal/ge-in-navbar/ge-in-navbar.component';
import { GeInFooterComponent } from './geeks/ge-internal/ge-in-footer/ge-in-footer.component';

import { WoExternalComponent } from './work/wo-external/wo-external.component';
import { WoExApplicationComponent } from './work/wo-external/wo-ex-application/wo-ex-application.component';
import { WoExLandingComponent } from './work/wo-external/wo-ex-landing/wo-ex-landing.component';
import { WoExLoginComponent } from './work/wo-external/wo-ex-login/wo-ex-login.component';
import { WoExPaypalComponent } from './work/wo-external/wo-ex-paypal/wo-ex-paypal.component';
import { WoExPortfolioComponent } from './work/wo-external/wo-ex-portfolio/wo-ex-portfolio.component';
import { WoExRecoverComponent } from './work/wo-external/wo-ex-recover/wo-ex-recover.component';
import { WoExRegisterComponent } from './work/wo-external/wo-ex-register/wo-ex-register.component';
import { WoExStudyComponent } from './work/wo-external/wo-ex-study/wo-ex-study.component';

import { WoTechnologyComponent } from './work/wo-technology/wo-technology.component';
import { WoTeRoleActionComponent } from './work/wo-technology/wo-te-role-action/wo-te-role-action.component';
import { WoTeRoleAuthComponent } from './work/wo-technology/wo-te-role-auth/wo-te-role-auth.component';
import { WoTeRoleDataComponent } from './work/wo-technology/wo-te-role-data/wo-te-role-data.component';
import { WoTeRoleFuncComponent } from './work/wo-technology/wo-te-role-func/wo-te-role-func.component';
import { WoTeRoleGroupComponent } from './work/wo-technology/wo-te-role-group/wo-te-role-group.component';
import { WoTeRolePermitComponent } from './work/wo-technology/wo-te-role-permit/wo-te-role-permit.component';
import { WoTeUserDataComponent } from './work/wo-technology/wo-te-user-data/wo-te-user-data.component';
import { WoTeUserEmailComponent } from './work/wo-technology/wo-te-user-email/wo-te-user-email.component';
import { WoTeUserIpComponent } from './work/wo-technology/wo-te-user-ip/wo-te-user-ip.component';

@NgModule({
  declarations: [
    AppComponent,

    GeExFooterComponent,
    GeExNavbarComponent,
    GeExProfileComponent,

    GeInHeaderComponent,
    GeInNavbarComponent,
    GeInFooterComponent,

    WoExternalComponent,
    WoExApplicationComponent,
    WoExLandingComponent,
    WoExLoginComponent,
    WoExPaypalComponent,
    WoExPortfolioComponent,
    WoExRecoverComponent,
    WoExRegisterComponent,
    WoExStudyComponent,

    WoTechnologyComponent,
    WoTeRoleActionComponent,
    WoTeRoleAuthComponent,
    WoTeRoleDataComponent,
    WoTeRoleFuncComponent,
    WoTeRoleGroupComponent,
    WoTeRolePermitComponent,
    WoTeUserDataComponent,
    WoTeUserEmailComponent,
    WoTeUserIpComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
