<app-ge-ex-profile></app-ge-ex-profile>
<main>
  <!-- section -->
  <section class="position-relative bnLanding">
    <img src="{{ urlFront }}/assets/apps/img/jpg/general/closeup-developer.jpg"
    alt="Background Image" class="img-fluid bnBackImage" />
    <div class="container my-lg-8 position-relative bnContainer bnBackContent">
      <div class="row align-items-center">
        <div class="col-12 mb-8"></div>
        <div class="col-lg-6 offset-lg-4 mb-6 mb-lg-0">
          <div class="text-center">
            <h1 class="display-3 fw-bold mb-3 text-dark">
              <span>Estudios</span>
            </h1>
            <h4 class="pe-lg-10 mb-5 text-dark">
              Gracias a mi estudios contribuyo al éxito en el mercado tecnológico dinámico,
              combinando creatividad e innovación para ofrecer soluciones tecnológicas integrales.
              ¡Estoy emocionado por lo que el futuro tiene reservado y cómo puedo
              aportar al mundo a través de mi pasión por el desarrollo de software!
            </h4>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>

<main class="docs-wrapper">
  <section class="container-fluid">
    <div class="row">
      <!-- Features -->
      <div class="col-4">
        <div class="card card-hover rounded-0 smooth-shadow-md mb-5 bg-white">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <span class="icon-shape icon-lg bg-light-warning rounded-circle text-center text-dark-warning fs-4">
                <i class="fe fe-video"></i>
              </span>
              <div class="ms-3">
                <h4 class="mb-0 fw-semibold">Avance Continuo</h4>
                <p class="mb-0">Impulsando mi carrera constantemente</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Features -->
      <div class="col-4">
        <div class="card card-hover rounded-0 smooth-shadow-md mb-5 bg-white">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <span class="icon-shape icon-lg bg-light-warning rounded-circle text-center text-dark-warning fs-4">
                <i class="fe fe-users"></i>
              </span>
              <div class="ms-3">
                <h4 class="mb-0 fw-semibold">Innovación Educativa</h4>
                <p class="mb-0">Explorando formas de aprendizaje educativas</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Features -->
      <div class="col-4">
        <div class="card card-hover rounded-0 smooth-shadow-md mb-5 bg-white">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <span class="icon-shape icon-lg bg-light-warning rounded-circle text-center text-dark-warning fs-4">
                <i class="fe fe-clock"></i>
              </span>
              <div class="ms-3">
                <h4 class="mb-0 fw-semibold">Desarrollo Personal</h4>
                <p class="mb-0">Crecimiento académicamente y personal</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="container-fluid">
    <div class="row">
      <div *ngFor="let response of dataStudy" class="col-lg-4 col-md-6 col-12">
        <div class="card card-hover rounded-0 smooth-shadow-md mb-5 bg-white">
          <a [href]="response.link" target="_blank" rel="noopener">
            <img [src]="response.image" alt="course" class="card-img-top rounded-0" />
          </a>
          <!-- Card Body -->
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-center mb-3">
              <span class="badge bg-danger-soft">{{ response.type }}</span>
              <a href="#" class="fs-5 d-none">
                <i class="fe fe-heart align-middle"></i>
              </a>
            </div>
            <h4 class="mb-2 text-truncate-line-2">
              <a [href]="response.link" target="_blank" rel="noopener" class="text-inherit">
                <span>{{ response.career }}</span>
              </a>
            </h4>

            <small class="text-inherit">
              By: {{ response.institute }}
            </small>
            <div class="lh-1 mt-3">
              <span class="align-text-top">
                <span class="fs-6">
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-star-fill text-warning" viewBox="0 0 16 16">
                    <path
                      d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-star-fill text-warning" viewBox="0 0 16 16">
                    <path
                      d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-star-fill text-warning" viewBox="0 0 16 16">
                    <path
                      d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-star-fill text-warning" viewBox="0 0 16 16">
                    <path
                      d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-star-fill text-warning" viewBox="0 0 16 16">
                    <path
                      d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                  </svg>
                </span>
              </span>
              <span class="text-warning px-1">{{ response.rate }}</span>
              <span class="text-inherit fs-6 px-1">{{ response.dateSince }} / {{ response.dateUntil }}</span>
            </div>
          </div>
          <!-- Card Footer -->
          <div class="card-footer">
            <div class="row align-items-center g-0">
              <div class="col">
                <h5 class="mb-0 text-inherit">
                  <span>{{ response.dateTime }}</span>
                </h5>
              </div>
              <div class="col-auto d-none">
                <span class="text-inherit">
                  <i class="fe fe-shopping-cart text-primary align-middle me-2 d-none"></i>
                  <span>{{ response.institute }}</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
