<main>
  <!-- section -->
  <section class="position-relative bnMdLanding">
    <div class="fixedElement">
      <img src="{{ urlFront }}/assets/apps/img/jpg/general/closeup-developer.jpg"
      alt="Background Image" class="img-fluid bnMdBackImage" />
      <div class="container my-lg-8 position-relative bnMdContainer nMdBackContent">
        <div class="row align-items-center">
          <div class="col-12 mb-8"></div>
          <div class="col-lg-10 offset-lg-2 mb-6 mb-lg-0">
            <div class="text-center">
              <h5 class="display-5 fw-bold mb-3 text-white">
                <span>Portafolio</span>
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>

<main class="docs_wrapper mt-6">
  <section class="container-fluid">
    <div class="row">
      <div class="col-12 mb-4"></div>
      <div class="offset-xxl-1 offset-xl-1 col-xxl-8 col-xl-8 col-md-12 col-sm-12 col-12">
        <div class="docs_content mx-xxl-9">
          <!-- Contenido Xampp 7 -->
          <div class="row sidebarTitles" id="titleXamppSeven">
            <!-- Contenido Tarjeta -->
            <div class="col-12">
              <div class="card rounded-0 smooth-shadow-md bg-white">
                <div class="card-body">
                  <div class="d-flex justify-content-between align-items-center mb-0 mt-0">
                    <div class="d-flex align-items-center">
                      <div class="ms-2 lh-1">
                        <h5 class="mb-1">
                          <span>Aplicaciones Desarrolladas</span>
                        </h5>
                        <span class="text-info">
                          Ambiente de Entorno: Xampp 7
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Separacion de Espacio -->
            <hr class="mt-4 mb-4">
            <!-- Contenido Tarjeta -->
            <ng-container *ngFor="let item of dtXamppSeven">
              <div class="col-12 col-md-4 col-lg-3" *ngIf="item.cd_status === 'active'">
                <div class="card card-hover rounded-0 smooth-shadow-md mb-5 bg-white">
                  <div class="card-header p-2">
                    <!--<div class="card-title">
                      <h4 class="form-label text-center">
                        <span>{{ item.os_title }}</span>
                      </h4>
                    </div>-->
                    <div class="d-flex align-items-center justify-content-between">
                      <div class="d-flex align-items-center">
                        <div *ngIf="item.os_repository === 'GitHub'"
                        class="icon-shape icon-lg rounded-3 border p-4">
                          <img src="{{ urlFront }}/assets/apps/img/svg/general/github.svg" alt="">
                        </div>
                        <div class="ms-3">
                          <h4 class="mb-0">
                            <code>{{ item.os_title }}</code>
                          </h4>
                          <span class="text-mutted">
                            <span [ngClass]="{
                              'badge': true,
                              'rounded-pill': true,
                              'bg-dark': item.os_repository === 'GitHub',
                              'bg-warning': item.os_repository === 'GitLab'
                            }">
                              {{ item.os_repository }}
                            </span>
                            <span class="text-white">:</span>
                            <span [ngClass]="{
                              'badge': true,
                              'rounded-pill': true,
                              'bg-danger': item.os_privacy === 'Privado',
                              'bg-success': item.os_privacy === 'Publico'
                            }">
                              {{ item.os_privacy }}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="responsive">
                      <table class="table table-bordered table-sm text-nowrap mb-0 table-centered text-center">
                        <caption class="text-center text-dark d-none">
                          <span>Tecnologias Aplicadas</span>
                        </caption>
                        <thead class="table-info text-dark">
                          <tr>
                            <td>Caracteristica</td>
                            <td>Acción</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="">
                            <td class="text-wrap text-dark">
                              <span>Base de Datos</span>
                            </td>
                            <td class="text-wrap text-dark">
                              <span>Visualizar</span>
                            </td>
                          </tr>
                          <tr class="">
                            <td class="text-wrap text-dark">
                              <span>Backend</span>
                            </td>
                            <td class="text-wrap text-dark">
                              <span>Visualizar</span>
                            </td>
                          </tr>
                          <tr class="">
                            <td class="text-wrap text-dark">
                              <span>Frontend</span>
                            </td>
                            <td class="text-wrap text-dark">
                              <span>Visualizar</span>
                            </td>
                          </tr>
                          <tr class="">
                            <td class="text-wrap text-dark">
                              <span>Plantilla</span>
                            </td>
                            <td class="text-wrap text-dark">
                              <span>Visualizar</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="responsive">
                      <table class="d-none table table-bordered table-sm text-nowrap mb-0 table-centered text-center">
                        <caption>Tecnologias Base Datos</caption>
                        <thead class="table-light">
                          <tr>
                            <td>Lenguaje</td>
                            <td>Version</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="text-wrap">
                              <span>MySQL</span>
                            </td>
                            <td class="text-wrap">
                              <span>5 o Superior</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="responsive">
                      <table class="d-none table table-bordered table-sm text-nowrap mb-0 table-centered text-center">
                        <caption>Tecnologias Backend</caption>
                        <thead class="table-light">
                          <tr>
                            <td>Lenguaje</td>
                            <td>Version</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="text-wrap">
                              <span>PHP</span>
                            </td>
                            <td class="text-wrap">
                              <span>7</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="responsive">
                      <table class="d-none table table-bordered table-sm text-nowrap mb-0 table-centered text-center">
                        <caption>Tecnologias Frontend</caption>
                        <thead class="table-light">
                          <tr>
                            <td>Lenguaje</td>
                            <td>Version</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="text-wrap">
                              <span>HTML</span>
                            </td>
                            <td class="text-wrap">
                              <span>5</span>
                            </td>
                          </tr>
                          <tr>
                            <td class="text-wrap">
                              <span>Bootstrap</span>
                            </td>
                            <td class="text-wrap">
                              <span>4</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="responsive">
                      <table class="d-none table table-bordered table-sm text-nowrap mb-0 table-centered text-center">
                        <caption>Tecnologias Plantillas</caption>
                        <thead class="table-light">
                          <tr>
                            <td>Tema</td>
                            <td>Version</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="text-wrap">
                              <span>Smart Admin</span>
                            </td>
                            <td class="text-wrap">
                              <span>4</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="row d-none">
                      <div class="col-12">
                        <div class="d-flex justify-content-between align-items-center">
                          <div class="text-end"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer">
                    <div class="row">
                      <div class="col-6">
                        <a [href]="item.os_link" target="_blank" rel="noopener"
                        class="btn btn-sm btn-outline-info w-100 rounded-0">
                          <span>Repositorio</span>
                        </a>
                      </div>
                      <div class="col-6">
                        <a [href]="item.os_demo" target="_blank" rel="noopener"
                      class="btn btn-sm btn-outline-success w-100 rounded-0">
                          <span>Aplicativo</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>


          <!-- Contenido Xampp 8 -->
          <div class="row sidebarTitles" id="titleXamppEight">
            <!-- Contenido Tarjeta -->
            <div class="col-12">
              <div class="card rounded-0 smooth-shadow-md bg-white">
                <div class="card-body">
                  <div class="d-flex justify-content-between align-items-center mb-0 mt-0">
                    <div class="d-flex align-items-center">
                      <div class="ms-2 lh-1">
                        <h5 class="mb-1">
                          <span>Aplicaciones Desarrolladas</span>
                        </h5>
                        <span class="text-info">
                          Ambiente de Entorno: Xampp 8
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Separacion de Espacio -->
            <hr class="mt-4 mb-4">
            <!-- Contenido Tarjeta -->
            <ng-container *ngFor="let item of dtXamppEight">
              <div class="col-12 col-md-4 col-lg-3" *ngIf="item.cd_status === 'active'">
                <div class="card card-hover rounded-0 smooth-shadow-md mb-5 bg-white">
                  <div class="card-header p-2">
                    <!--<div class="card-title">
                      <h4 class="form-label text-center">
                        <span>{{ item.os_title }}</span>
                      </h4>
                    </div>-->
                    <div class="d-flex align-items-center justify-content-between">
                      <div class="d-flex align-items-center">
                        <div *ngIf="item.os_repository === 'GitHub'"
                        class="icon-shape icon-lg rounded-3 border p-4">
                          <img src="{{ urlFront }}/assets/apps/img/svg/general/github.svg" alt="">
                        </div>
                        <div class="ms-3">
                          <h4 class="mb-0">
                            <code>{{ item.os_title }}</code>
                          </h4>
                          <span class="text-mutted">
                            <span [ngClass]="{
                              'badge': true,
                              'rounded-pill': true,
                              'bg-dark': item.os_repository === 'GitHub',
                              'bg-warning': item.os_repository === 'GitLab'
                            }">
                              {{ item.os_repository }}
                            </span>
                            <span class="text-white">:</span>
                            <span [ngClass]="{
                              'badge': true,
                              'rounded-pill': true,
                              'bg-danger': item.os_privacy === 'Privado',
                              'bg-success': item.os_privacy === 'Publico'
                            }">
                              {{ item.os_privacy }}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="responsive">
                      <table class="table table-bordered table-sm text-nowrap mb-0 table-centered text-center">
                        <caption class="text-center text-dark d-none">
                          <span>Tecnologias Aplicadas</span>
                        </caption>
                        <thead class="table-info text-dark">
                          <tr>
                            <td>Caracteristica</td>
                            <td>Acción</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="">
                            <td class="text-wrap text-dark">
                              <span>Base de Datos</span>
                            </td>
                            <td class="text-wrap text-dark">
                              <span>Visualizar</span>
                            </td>
                          </tr>
                          <tr class="">
                            <td class="text-wrap text-dark">
                              <span>Backend</span>
                            </td>
                            <td class="text-wrap text-dark">
                              <span>Visualizar</span>
                            </td>
                          </tr>
                          <tr class="">
                            <td class="text-wrap text-dark">
                              <span>Frontend</span>
                            </td>
                            <td class="text-wrap text-dark">
                              <span>Visualizar</span>
                            </td>
                          </tr>
                          <tr class="">
                            <td class="text-wrap text-dark">
                              <span>Plantilla</span>
                            </td>
                            <td class="text-wrap text-dark">
                              <span>Visualizar</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="responsive">
                      <table class="d-none table table-bordered table-sm text-nowrap mb-0 table-centered text-center">
                        <caption>Tecnologias Base Datos</caption>
                        <thead class="table-light">
                          <tr>
                            <td>Lenguaje</td>
                            <td>Version</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="text-wrap">
                              <span>MySQL</span>
                            </td>
                            <td class="text-wrap">
                              <span>5 o Superior</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="responsive">
                      <table class="d-none table table-bordered table-sm text-nowrap mb-0 table-centered text-center">
                        <caption>Tecnologias Backend</caption>
                        <thead class="table-light">
                          <tr>
                            <td>Lenguaje</td>
                            <td>Version</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="text-wrap">
                              <span>PHP</span>
                            </td>
                            <td class="text-wrap">
                              <span>7</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="responsive">
                      <table class="d-none table table-bordered table-sm text-nowrap mb-0 table-centered text-center">
                        <caption>Tecnologias Frontend</caption>
                        <thead class="table-light">
                          <tr>
                            <td>Lenguaje</td>
                            <td>Version</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="text-wrap">
                              <span>HTML</span>
                            </td>
                            <td class="text-wrap">
                              <span>5</span>
                            </td>
                          </tr>
                          <tr>
                            <td class="text-wrap">
                              <span>Bootstrap</span>
                            </td>
                            <td class="text-wrap">
                              <span>4</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="responsive">
                      <table class="d-none table table-bordered table-sm text-nowrap mb-0 table-centered text-center">
                        <caption>Tecnologias Plantillas</caption>
                        <thead class="table-light">
                          <tr>
                            <td>Tema</td>
                            <td>Version</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="text-wrap">
                              <span>Smart Admin</span>
                            </td>
                            <td class="text-wrap">
                              <span>4</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="row d-none">
                      <div class="col-12">
                        <div class="d-flex justify-content-between align-items-center">
                          <div class="text-end"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer">
                    <div class="row">
                      <div class="col-6">
                        <a [href]="item.os_link" target="_blank" rel="noopener"
                        class="btn btn-sm btn-outline-info w-100 rounded-0">
                          <span>Repositorio</span>
                        </a>
                      </div>
                      <div class="col-6">
                        <a [href]="item.os_demo" target="_blank" rel="noopener"
                      class="btn btn-sm btn-outline-success w-100 rounded-0">
                          <span>Aplicativo</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>


          <!-- Contenido WorkSpace -->
          <div class="row sidebarTitles" id="titleWorkSpace">
            <!-- Contenido Tarjeta -->
            <div class="col-12">
              <div class="card rounded-0 smooth-shadow-md bg-white">
                <div class="card-body">
                  <div class="d-flex justify-content-between align-items-center mb-0 mt-0">
                    <div class="d-flex align-items-center">
                      <div class="ms-2 lh-1">
                        <h5 class="mb-1">
                          <span>Aplicaciones Desarrolladas</span>
                        </h5>
                        <span class="text-info">
                          Ambiente de Entorno: IIS (Internet Informacion Services)
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Separacion de Espacio -->
            <hr class="mt-4 mb-4">
            <!-- Contenido Tarjeta -->
            <ng-container *ngFor="let item of dtWorkSpace">
              <div class="col-12 col-md-4 col-lg-3" *ngIf="item.cd_status === 'active'">
                <div class="card card-hover rounded-0 smooth-shadow-md mb-5 bg-white">
                  <div class="card-header p-2">
                    <!--<div class="card-title">
                      <h4 class="form-label text-center">
                        <span>{{ item.os_title }}</span>
                      </h4>
                    </div>-->
                    <div class="d-flex align-items-center justify-content-between">
                      <div class="d-flex align-items-center">
                        <div *ngIf="item.os_repository === 'GitHub'"
                        class="icon-shape icon-lg rounded-3 border p-4">
                          <img src="{{ urlFront }}/assets/apps/img/svg/general/github.svg" alt="">
                        </div>
                        <div class="ms-3">
                          <h4 class="mb-0">
                            <code>{{ item.os_title }}</code>
                          </h4>
                          <span class="text-mutted">
                            <span [ngClass]="{
                              'badge': true,
                              'rounded-pill': true,
                              'bg-dark': item.os_repository === 'GitHub',
                              'bg-warning': item.os_repository === 'GitLab'
                            }">
                              {{ item.os_repository }}
                            </span>
                            <span class="text-white">:</span>
                            <span [ngClass]="{
                              'badge': true,
                              'rounded-pill': true,
                              'bg-danger': item.os_privacy === 'Privado',
                              'bg-success': item.os_privacy === 'Publico'
                            }">
                              {{ item.os_privacy }}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="responsive">
                      <table class="table table-bordered table-sm text-nowrap mb-0 table-centered text-center">
                        <caption class="text-center text-dark d-none">
                          <span>Tecnologias Aplicadas</span>
                        </caption>
                        <thead class="table-info text-dark">
                          <tr>
                            <td>Caracteristica</td>
                            <td>Acción</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="">
                            <td class="text-wrap text-dark">
                              <span>Base de Datos</span>
                            </td>
                            <td class="text-wrap text-dark">
                              <span>Visualizar</span>
                            </td>
                          </tr>
                          <tr class="">
                            <td class="text-wrap text-dark">
                              <span>Backend</span>
                            </td>
                            <td class="text-wrap text-dark">
                              <span>Visualizar</span>
                            </td>
                          </tr>
                          <tr class="">
                            <td class="text-wrap text-dark">
                              <span>Frontend</span>
                            </td>
                            <td class="text-wrap text-dark">
                              <span>Visualizar</span>
                            </td>
                          </tr>
                          <tr class="">
                            <td class="text-wrap text-dark">
                              <span>Plantilla</span>
                            </td>
                            <td class="text-wrap text-dark">
                              <span>Visualizar</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="responsive">
                      <table class="d-none table table-bordered table-sm text-nowrap mb-0 table-centered text-center">
                        <caption>Tecnologias Base Datos</caption>
                        <thead class="table-light">
                          <tr>
                            <td>Lenguaje</td>
                            <td>Version</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="text-wrap">
                              <span>MySQL</span>
                            </td>
                            <td class="text-wrap">
                              <span>5 o Superior</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="responsive">
                      <table class="d-none table table-bordered table-sm text-nowrap mb-0 table-centered text-center">
                        <caption>Tecnologias Backend</caption>
                        <thead class="table-light">
                          <tr>
                            <td>Lenguaje</td>
                            <td>Version</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="text-wrap">
                              <span>PHP</span>
                            </td>
                            <td class="text-wrap">
                              <span>7</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="responsive">
                      <table class="d-none table table-bordered table-sm text-nowrap mb-0 table-centered text-center">
                        <caption>Tecnologias Frontend</caption>
                        <thead class="table-light">
                          <tr>
                            <td>Lenguaje</td>
                            <td>Version</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="text-wrap">
                              <span>HTML</span>
                            </td>
                            <td class="text-wrap">
                              <span>5</span>
                            </td>
                          </tr>
                          <tr>
                            <td class="text-wrap">
                              <span>Bootstrap</span>
                            </td>
                            <td class="text-wrap">
                              <span>4</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="responsive">
                      <table class="d-none table table-bordered table-sm text-nowrap mb-0 table-centered text-center">
                        <caption>Tecnologias Plantillas</caption>
                        <thead class="table-light">
                          <tr>
                            <td>Tema</td>
                            <td>Version</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="text-wrap">
                              <span>Smart Admin</span>
                            </td>
                            <td class="text-wrap">
                              <span>4</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="row d-none">
                      <div class="col-12">
                        <div class="d-flex justify-content-between align-items-center">
                          <div class="text-end"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer">
                    <div class="row">
                      <div class="col-6">
                        <a [href]="item.os_link" target="_blank" rel="noopener"
                        class="btn btn-sm btn-outline-info w-100 rounded-0">
                          <span>Repositorio</span>
                        </a>
                      </div>
                      <div class="col-6">
                        <a [href]="item.os_demo" target="_blank" rel="noopener"
                      class="btn btn-sm btn-outline-success w-100 rounded-0">
                          <span>Aplicativo</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <!-- Sidebar nav fixed -->
      <div class="col-xl-2 col-lg-2 col-md-6 col-sm-12 col-12 d-none d-xl-block position-fixed end-0">
        <div class="sidebar-nav-fixed">
          <span class="px-4 mb-2 d-block text-uppercase ls-md fw-semibold fs-6">
            Contenido
          </span>
          <ul class="list-unstyled">
            <li>
              <a href="external/portfolio#titleXamppSeven" class="text-info active">
                <span>Xampp 7</span>
              </a>
            </li>
            <li>
              <a href="external/portfolio#titleXamppEight">
                <span>Xampp 8</span>
              </a>
            </li>
            <li>
              <a href="external/portfolio#titleWorkSpace">
                <span>IIS (Internet Informacion Services)</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</main>
