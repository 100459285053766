import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, firstValueFrom, Observable, tap, throwError } from 'rxjs';
import { ConsoleService } from 'src/app/services/functions/console/console.service';
import { SweetService } from 'src/app/services/functions/sweet/sweet.service';
import { Environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class JsCheckService {
  jsHost = Environment.api.java.spring.host;
  jsApp = Environment.api.java.spring.app;
  jsNet = Environment.api.java.spring.net;
  jsTest = Environment.api.java.spring.test;
  jsWork = Environment.api.java.spring.work;

  constructor(
    public http: HttpClient,
    public srvConsole: ConsoleService,
    public srvSweet: SweetService,
  ) { }

  available(): Observable<any> {
    console.warn('available');
    let url = this.jsHost;
    let api = `pub/check/verify`;
    return this.http.get<any>(`${url}/${api}`).pipe(
      catchError((e) => {
        console.log('error', e);
        return throwError(() => e);
      }),
      tap((response: any) => {
        console.log('response', response);
      }),
    );
  }

  async verify(): Promise<any> {
    //this.srvSweet.process();
    try {
      // Obtener respuesta de la solicitud
      const response = await firstValueFrom(
        // Ejecutar solicitud
        this.available()
      );
      // Mensaje para imprimir en consola
      let message = `service response check`;
      // Imprimir en consola el mensaje
      this.srvConsole.print('sccs', message);
      // Retornar respuesta
      return response;
    } catch (error) {
      // Cerrar ventana de alerta
      this.srvSweet.close();
      // Mensaje para imprimir en consola
      let message = `service unavailable`;
      // Imprimir en consola el mensaje
      this.srvConsole.print('error', message);
      // Abrir ventana de alerta
      setTimeout(() => { this.srvSweet.service(); }, 250);
      // Retornar falso
      return false;
    }
  }
}
