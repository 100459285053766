import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { AuthService } from 'src/app/services/functions/auth/auth.service';
import { ConsoleService } from 'src/app/services/functions/console/console.service';
import { Environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class JsReadService {
  jsHost = Environment.api.java.spring.host;
  jsApp = Environment.api.java.spring.app;
  jsNet = Environment.api.java.spring.net;
  jsTest = Environment.api.java.spring.test;
  jsWork = Environment.api.java.spring.work;

  constructor(
    public http: HttpClient,
    public srvAuth: AuthService,
    public srvConsole: ConsoleService,
  ) { }

  getAll(mapping: any): Observable<any> {
    let headers = this.srvAuth.getBearer();
    //let message = `headers: ${headers}`;
    this.srvConsole.print('json', headers);

    let url = `${this.jsHost}/api/${mapping}`;
    let endpoint = `${url}/get/all`;

    // Obtener opciones para las solicitudes
    let options = this.optionHttp(headers);

    return this.http.get<any>(
      endpoint, options
    ).pipe(
      catchError((response) => {
        console.error('response', response);
        return throwError(() => response);
      })
    );
  }

  getPage(mapping: any): Observable<any> {
    let headers = this.srvAuth.getBearer();
    //let message = `headers: ${headers}`;
    this.srvConsole.print('json', headers);

    let url = `${this.jsHost}/api/${mapping}`;
    let endpoint = `${url}/page/all?sheet=0&row=25`;

    // Obtener opciones para las solicitudes
    let options = this.optionHttp(headers);

    return this.http.get<any>(
      endpoint, options
    ).pipe(
      catchError((response) => {
        console.error('response', response);
        return throwError(() => response);
      })
    );
  }

  private optionHttp(headers?: any): any {
    let options: any = {
      // Observar toda la respuesta
      observe: 'response' as const,
      // Contexto para la solicitud
      context: new HttpContext(),
      // Informar sobre el progreso
      reportProgress: true,
      // Tipo de respuesta
      responseType: 'json',
      // Enviar credenciales
      withCredentials: false,
    };

    // Añadir headers cuando no sea nulo
    if (headers && headers !== null) {
      options.headers = headers;
    }

    return options;
  }
}
