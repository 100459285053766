import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DomainService {

  constructor() { }

  getDomainVerify(winLoc: any = '') {
    const { hostname, } = window.location;
    // Verificar entorno de trabajo localhost
    if (hostname.includes('localhost')) {
      console.log('environment: localhost');
      winLoc = hostname;
    }
    // Verificar si el dominio no tiene subdominio y no es localhost
    if (hostname.split('.').length <= 2 && !hostname.includes('localhost')) {
      console.log('environment: production');
      // Redirigir a la versión con www
      window.location.href = `https://www.${hostname}`;
    }
    return winLoc;
  }

  getDomainInfo(result: any = '') {
    //console.warn('function getDomainInfo');
    //console.log(window.location);
    const { hostname, origin } = window.location;
    // Verificar entorno de trabajo development
    if (hostname.includes('localhost')) {
      //console.log('environment: localhost');
      result = origin;
    }

    // Verificar entorno de trabajo production
    if (!hostname.includes('localhost')) {
      let winPtcl = this.getDomainPtcls();
      let winSub = this.getDomainSubd();
      let winMain = this.getDomainMain();
      let winExt = this.getDomainExt();
      let winCtry = this.getDomainCtry();
      let valCtry = (winCtry && winCtry.trim() !== '') ? `.${winCtry}` : '';
      result = `${winPtcl}//${winSub}.${winMain}.${winExt}${valCtry}`;
    }

    return result;
  }

  // Obtener Protocolo
  getDomainPtcls() {
    const { protocol } = window.location;
    return protocol;
  }

  // Obtener Subdominio
  getDomainSubd() {
    const { hostname } = window.location;
    const parts = hostname.split('.');
    return parts.length > 0 ? parts[0] : '';
  }

  // Obtener Dominio
  getDomainMain() {
    const { hostname } = window.location;
    const parts = hostname.split('.');
    return parts.length > 1 ? parts[1] : '';
  }

  // Obtener Extensión del Dominio
  getDomainExt() {
    const { hostname } = window.location;
    const parts = hostname.split('.');
    return parts.length > 2 ? parts[2] : '';
  }

  // Obtener País (Extensión del dominio)
  getDomainCtry() {
    const { hostname } = window.location;
    const parts = hostname.split('.');
    return parts.length > 3 ? parts[3] : '';
  }

  getSubOther(sub: any, endpoint: any, result: any = '') {
    const { hostname, origin } = window.location;
    // Verificar entorno de trabajo development
    if (hostname.includes('localhost')) {
      //console.log('environment: localhost');
      result = origin;
    }

    // Verificar entorno de trabajo production
    if (!hostname.includes('localhost')) {
      let winEnd = endpoint ? `/${endpoint}` : '';
      let winPtcl = this.getDomainPtcls();
      let winMain = this.getDomainMain();
      let winExt = this.getDomainExt();
      let winCtry = this.getDomainCtry();
      let valCtry = (winCtry && winCtry.trim() !== '') ? `.${winCtry}` : '';
      result = `${winPtcl}//${sub}.${winMain}.${winExt}${valCtry}${winEnd}`;
    }

    return result;
  }
}
