import { Component, HostListener, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import Swal from 'sweetalert2';

import { Environment } from 'src/environments/environment';
import { BaseurlService } from 'src/app/services/functions/baseurl/baseurl.service';
import { DomainService } from 'src/app/services/functions/domain/domain.service';
import { ConsoleService } from 'src/app/services/functions/console/console.service';
import { JsAuthService } from 'src/app/services/apis/java/spring/js-auth/js-auth.service';
import { JsCheckService } from 'src/app/services/apis/java/spring/js-check/js-check.service';
import { TgUserDataService } from 'src/app/services/modules/technology/tg-user-data/tg-user-data.service';

import { TgUserData } from 'src/app/interfaces/modules/technology/tg-user-data';

declare let bootstrap: any;

@Component({
  selector: 'app-wo-te-user-data',
  templateUrl: './wo-te-user-data.component.html',
  styleUrls: ['./wo-te-user-data.component.css']
})
export class WoTeUserDataComponent implements OnInit {
  appTitle: string = Environment.title;
  domainMajor: string = '';
  urlFront: string = '';

  codeLink: string[] = [];
  codeScript: string[] = [];
  geeksData: any[] = [];
  geeksJson: string = 'assets/geeks/json';
  geeksFile: string = 'data.json';
  geeksUrl: string = '';

  isLoading: boolean = false;
  tgUserDataGet: TgUserData[] = [];
  pageData: any[] = [];
  pageColumn: string[] = [];

  constructor (
    private renderer: Renderer2,
    private router: Router,
    private serviceBaseUrl: BaseurlService,
    private serviceDomain: DomainService,
    private serviceConsole: ConsoleService,
    private javaSpringAuth: JsAuthService,
    private javaSpringCheck: JsCheckService,
    private serviceTgUserData: TgUserDataService,
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.adjustTableHeight();
  }

  adjustTableHeight() {
    const tableWrapper = document.querySelector('.table-wrapper') as HTMLElement;
    if (tableWrapper) {
      let size = `${window.innerHeight * 0.75}px`;
      tableWrapper.style.maxHeight = size;
    }
  }

  adjustTableHeight_() {
    const table = document.querySelector('.cont-table') as HTMLElement;
    const header = document.querySelector('.cont-title') as HTMLElement;
    const footer = document.querySelector('.cont-footer') as HTMLElement;

    if (table && header && footer) {
      const headerHeight = header.offsetHeight;
      this.serviceConsole.info(headerHeight);
      const footerHeight = footer.offsetHeight;
      this.serviceConsole.info(headerHeight);
      const availableHeight = window.innerHeight - headerHeight - footerHeight;
      this.serviceConsole.info(availableHeight);
      table.style.maxHeight = `${availableHeight}px`;
    }
  }

  async ngOnInit(): Promise<void> {
    this.getDomain();
    this.getUrlHref();
    //await this.tmplGeeks();
    //this.moduleLoading('all');
    this.moduleLoading('page');
  }

  getDomain() {
    let dmMajor = this.serviceDomain.getDomainInfo();
    this.domainMajor = dmMajor;
  }

  getUrlHref() {
    let urlDomain = this.domainMajor;
    let urlBase = this.serviceBaseUrl.getBaseUrl();
    if (urlBase === '/') { urlBase = ''; }
    this.urlFront = urlDomain + urlBase;
    console.log(`urlFront: ${this.urlFront}`);
  }

  async tmplGeeks() {
    this.urlGeeks();
    await this.jsonGeeks();
    this.idxHeadFilter();
    this.idxHeadLink();
    this.idxScriptFilter();
    this.idxScriptLink();
  }

  urlGeeks() {
    this.geeksUrl = `${this.geeksJson}/${this.geeksFile}`;
  }

  async jsonGeeks() {
    let urlData = `${this.urlFront}/${this.geeksUrl}`;
    const dataFetch = await this.dataFetch(urlData);
    if (dataFetch && dataFetch.length > 0) {
      //console.log('dataFetch', dataFetch);
      this.geeksData = dataFetch;
    }
  }

  async dataFetch(url: any) {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(
          `HTTP error! status: ${response.status}`
        );
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching the JSON data:', error);
      return null;
    }
  }

  idxHeadFilter() {
    this.codeLink.push('5HI8o45JobiJu0riQlFl');
    this.codeLink.push('t5e8u6eglgIp1aStIdip');
  }

  idxHeadLink() {
    this.geeksData.filter(
      item => item.type === 'link' &&
      this.codeLink.includes(item.code)
    ).forEach(item => {
      const linkElement = this.renderer.createElement('link');
      this.renderer.setAttribute(linkElement, 'rel', 'stylesheet');
      this.renderer.setAttribute(linkElement, 'href', item.link);
      this.renderer.appendChild(document.head, linkElement);
    });
  }

  idxScriptFilter() {
    this.codeScript.push('wrlduvLzL3asoqupRAbl');
    this.codeScript.push('PH0CrLsPuga9i1isTuSW');
    this.codeScript.push('chotr0s8O5OHUSP5prIm');
    this.codeScript.push('gifaylz1V4qIgltRUthi');
    this.codeScript.push('p9ljLvIyAw5haq7q9zaY');
    this.codeScript.push('DR87WOwl5LSwl3iPaC4e');
    this.codeScript.push('52b4wrEqUtRawra0IsW6');
    this.codeScript.push('tAslpitRAZimibE6owre');
    this.codeScript.push('2Rat2iTih1wisWUt1UrI');
    this.codeScript.push('tru7rlfri2lwAqixophi');
    this.codeScript.push('pesTecif8quzasOspi3l');
    this.codeScript.push('s3A00aDORekEtrenEjIP');
    this.codeScript.push('Q0phaTREsposAf3y42rE');
    this.codeScript.push('f8usP4mEk1Chub6iziWr');
    this.codeScript.push('spublw2lQEchiqudrlhU');
  }

  idxScriptLink() {
    this.geeksData.filter(
      item => item.type === 'script' &&
      this.codeScript.includes(item.code)
    ).forEach(item => {
      const scriptElement = this.renderer.createElement('script');
      this.renderer.setAttribute(scriptElement, 'src', item.link);
      this.renderer.appendChild(document.body, scriptElement);
    });
  }

  async actionCheck(): Promise<any> {
    try {
      const response = await firstValueFrom(
        this.javaSpringCheck.available()
      );
      //console.log('Response from backend check:', response);
      // Devuelve true si el backend está disponible
      return response.status === true;
    } catch (error) {
      //console.error('Error al verificar la disponibilidad del backend:', error);
      this.openModalById('modalAlertAvailable');
      // Devuelve false si hay un error o el backend no está disponible
      return false;
    }
  }

  loadingStatus(response: boolean) {
    this.isLoading = response;
  }

  elementsId(id: string) {
    return document.getElementById(id);
  }

  openModalById(modalId: string) {
    const modalElement = this.elementsId(modalId);
    if (modalElement) {
      const modalInfo = new bootstrap.Modal(
        modalElement
      );
      modalInfo.show();
    } else {
      console.error(`Modal with ID ${modalId} not found.`);
    }
  }

  async moduleLoading(filter: string) {
    this.loadingStatus(true);
    const available = await this.actionCheck();
    if (!available) { this.loadingStatus(false); }
    if (available && filter === 'all') {
      this.dataProccessAll();
    }
    if (available && filter === 'page') {
      this.dataProccessPage();
    }
  }

  dataProccessAll() {
    this.serviceTgUserData.jsGetAll().subscribe({
      next: (response: any) => {
        this.dataEndingAll(response);
      },
      error: (response: any) => {
        this.dataEndingAll(response);
      },
      complete: () => {
        //console.log('request completed');
      },
    });
  }

  dataEndingAll(response: any) {
    //console.warn('dataEndingAll');
    //console.log('response', response);
    let dataSweet = { html: '', icon: '', title: '', };
    this.loadingStatus(false);
    if (response.status === 403) {
      dataSweet.html = 'Permisos insuficientes!';
      dataSweet.icon = 'warning';
      dataSweet.title = 'Acceso Denegado!';
      this.messageLoading(dataSweet, response);
    }
    if (response.status === 200) {
      this.dataResultAll(response);
    }
  }

  dataResultAll(response: any) {
    this.serviceConsole.warn('dataResultAll');
    console.log(response);
  }

  dataProccessPage() {
    this.serviceTgUserData.jsGetPage().subscribe({
      next: (response: any) => {
        this.dataEndingPage(response);
      },
      error: (response: any) => {
        this.dataEndingPage(response);
      },
      complete: () => {
        //console.log('request completed');
      },
    });
  }

  dataEndingPage(response: any) {
    //console.warn('dataEndingPage');
    //console.log('response', response);
    let dataSweet = { html: '', icon: '', title: '', };
    this.loadingStatus(false);
    if (response.status === 403) {
      dataSweet.html = 'Permisos insuficientes!';
      dataSweet.icon = 'warning';
      dataSweet.title = 'Acceso Denegado!';
      this.messageLoading(dataSweet, response);
    }
    if (response.status === 200) {
      this.dataResultPage(response);
    }
  }

  dataResultPage(response: any) {
    this.serviceConsole.warn('dataResultPage');
    console.log(response);
    this.dataPageCol(response);
    this.dataPageCont(response);
  }

  dataPageCol(response: any) {
    this.serviceConsole.warn('dataPageCol');
    let data = response.body.content;
    const allKeys: Set<string> = new Set();

    // Recopilar claves de los objetos
    data.forEach((item: {}) => {
      console.log(item);
      Object.keys(item).forEach(key => {
        allKeys.add(key);
      });
    });

    // Convertir el Set en un array de claves únicas
    this.pageColumn = Array.from(allKeys);
    console.log(this.pageColumn);
  }

  dataPageCont(response: any) {
    // Usar encadenación opcional
    const content = response?.body?.content || [];

    // Verificar la existencia de propiedades
    if (content.length > 0) {
      this.pageData = content;
    }

    // Ajustar la altura de la tabla después de cargar los datos
    this.adjustTableHeight();
  }

  messageLoading(message: any, response: any) {
    //console.warn('messageLoading');
    //console.log('message', message);
    //console.log('response', response);
    Swal.fire({
      allowOutsideClick: false,
      confirmButtonText: 'Entendido',
      customClass: {
        confirmButton: 'rounded-0 btn btn-md btn-success',
      },
      html: `<span class="text-dark">${message.html}</span>`,
      icon: message.icon,
      title: `<h3>${message.title}!</h3>`,
    }).then(() => {
      this.redirectMain();
    });
  }

  redirectMain() {
    this.router.navigate(
      ['/internal/dashboard']
    );
  }
}
