import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { BaseurlService } from 'src/app/services/functions/baseurl/baseurl.service';
import { ConsoleService } from 'src/app/services/functions/console/console.service';
import { DomainService } from 'src/app/services/functions/domain/domain.service';
import { Environment } from 'src/environments/environment';

@Component({
  selector: 'app-wo-technology',
  templateUrl: './wo-technology.component.html',
  styleUrls: ['./wo-technology.component.css']
})
export class WoTechnologyComponent implements OnInit {
  env: any = Environment;
  debug: string = this.env.app.debug;
  title: string = this.env.app.title;

  domainInfo: string = '';
  domCoreExt: string = '';
  domainPtcl: string = '';
  urlFront: string = '';

  codeLink: string[] = [];
  codeScript: string[] = [];
  geeksData: any[] = [];
  geeksJson: string = 'assets/geeks/json';
  geeksFile: string = 'data.json';
  geeksUrl: string = '';

  constructor (
    public renderer: Renderer2,
    public router: Router,
    public srvBaseUrl: BaseurlService,
    public srvConsole: ConsoleService,
    public srvDomain: DomainService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.setComponent();
    this.getDomEnv();
    this.getDomain();
    this.getCoreExt();
    this.getUrlHref();

    //await this.tmplGeeks();
  }

  setComponent() {
    // Obtener el nombre del componente automaticamente
    let information = this.constructor.name;
    let finalData = information.replace('Component', '');
    let message = `Current Component: ${finalData}`;
    this.debug && this.srvConsole.print('warn', message);
  }

  getDomEnv() {
    let domainCheck = this.srvDomain.getDomainVerify();
    let mssgVerify = `enviroment check: ${domainCheck}`;
    this.debug && this.srvConsole.print('info', mssgVerify);
  }

  getDomain() {
    // Obtener el dominio completo de la url
    this.domainInfo = this.srvDomain.getDomainInfo();
    let messageInfo = `Domain URL: ${this.domainInfo}`;
    this.debug && this.srvConsole.print('info', messageInfo);
  }

  getCoreExt() {
    // Obtener unicamente el dominio actual
    let domainCore = this.srvDomain.getDomainCore();
    let domainExt = this.srvDomain.getDomainExt();
    this.domCoreExt = `${domainCore}.${domainExt}`;
    let messageCore = `Core Ext: ${this.domCoreExt}`;
    this.debug && this.srvConsole.print('info', messageCore);
  }

  getUrlHref() {
    let urlDomain = this.domainInfo;
    let urlBase = this.srvBaseUrl.getBaseUrl();
    if (urlBase === '/') { urlBase = ''; }
    this.urlFront = urlDomain + urlBase;
    let message = `URL Front: ${this.urlFront}`;
    this.debug && this.srvConsole.print('info', message);
  }

  async tmplGeeks() {
    this.urlGeeks();
    await this.jsonGeeks();
    this.idxHeadFilter();
    this.idxHeadLink();
    this.idxScriptFilter();
    this.idxScriptLink();
  }

  urlGeeks() {
    this.geeksUrl = `${this.geeksJson}/${this.geeksFile}`;
  }

  async jsonGeeks() {
    let urlData = `${this.urlFront}/${this.geeksUrl}`;
    const dataFetch = await this.dataFetch(urlData);
    if (dataFetch && dataFetch.length > 0) {
      console.log('dataFetch', dataFetch);
      this.geeksData = dataFetch;
    }
  }

  async dataFetch(url: any) {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(
          `HTTP error! status: ${response.status}`
        );
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching the JSON data:', error);
      return null;
    }
  }

  idxHeadFilter() {
    this.codeLink.push('5HI8o45JobiJu0riQlFl');
    this.codeLink.push('t5e8u6eglgIp1aStIdip');
  }

  idxHeadLink() {
    this.geeksData.filter(
      item => item.type === 'link' &&
      this.codeLink.includes(item.code)
    ).forEach(item => {
      const linkElement = this.renderer.createElement('link');
      this.renderer.setAttribute(linkElement, 'rel', 'stylesheet');
      this.renderer.setAttribute(linkElement, 'href', item.link);
      this.renderer.appendChild(document.head, linkElement);
    });
  }

  idxScriptFilter() {
    this.codeScript.push('wrlduvLzL3asoqupRAbl');
    this.codeScript.push('PH0CrLsPuga9i1isTuSW');
    this.codeScript.push('chotr0s8O5OHUSP5prIm');
    this.codeScript.push('gifaylz1V4qIgltRUthi');
    this.codeScript.push('p9ljLvIyAw5haq7q9zaY');
    this.codeScript.push('DR87WOwl5LSwl3iPaC4e');
    this.codeScript.push('52b4wrEqUtRawra0IsW6');
    this.codeScript.push('tAslpitRAZimibE6owre');
    this.codeScript.push('2Rat2iTih1wisWUt1UrI');
    this.codeScript.push('tru7rlfri2lwAqixophi');
    this.codeScript.push('pesTecif8quzasOspi3l');
    this.codeScript.push('s3A00aDORekEtrenEjIP');
    this.codeScript.push('Q0phaTREsposAf3y42rE');
    this.codeScript.push('f8usP4mEk1Chub6iziWr');
    this.codeScript.push('spublw2lQEchiqudrlhU');
  }

  idxScriptLink() {
    this.geeksData.filter(
      item => item.type === 'script' &&
      this.codeScript.includes(item.code)
    ).forEach(item => {
      const scriptElement = this.renderer.createElement('script');
      this.renderer.setAttribute(scriptElement, 'src', item.link);
      this.renderer.appendChild(document.body, scriptElement);
    });
  }
}
