import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BaseurlService {
  public baseUrl: string;

  constructor() {
    this.baseUrl = this.extract();
  }

  private extract(): string {
    const baseTag = document.querySelector('base');
    return baseTag?.getAttribute('href') ?? '/';
  }

  getBaseUrl(): string {
    return this.baseUrl;
  }
}
