import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseurlService } from 'src/app/services/functions/baseurl/baseurl.service';
import { ConsoleService } from 'src/app/services/functions/console/console.service';
import { DomainService } from 'src/app/services/functions/domain/domain.service';
import { Environment } from 'src/environments/environment';

@Component({
  selector: 'app-wo-ex-study',
  templateUrl: './wo-ex-study.component.html',
  styleUrls: ['./wo-ex-study.component.css']
})
export class WoExStudyComponent implements OnInit {
  env: any = Environment;
  debug: string = this.env.app.debug;
  title: string = this.env.app.title;

  domainInfo: string = '';
  domCoreExt: string = '';
  domainPtcl: string = '';
  urlFront: string = '';

  dataStudy: any[] = [];

  constructor (
    public router: Router,
    public srvBaseUrl: BaseurlService,
    public srvConsole: ConsoleService,
    public srvDomain: DomainService,
  ) {}

  ngOnInit(): void {
    this.setComponent();
    this.getDomEnv();
    this.getDomain();
    this.getCoreExt();
    this.getUrlHref();

    this.dataJson();
  }

  setComponent() {
    // Obtener el nombre del componente automaticamente
    let information = this.constructor.name;
    let finalData = information.replace('Component', '');
    let message = `Current Component: ${finalData}`;
    this.debug && this.srvConsole.print('warn', message);
  }

  getDomEnv() {
    let domainCheck = this.srvDomain.getDomainVerify();
    let mssgVerify = `enviroment check: ${domainCheck}`;
    this.debug && this.srvConsole.print('info', mssgVerify);
  }

  getDomain() {
    // Obtener el dominio completo de la url
    this.domainInfo = this.srvDomain.getDomainInfo();
    let messageInfo = `Domain URL: ${this.domainInfo}`;
    this.debug && this.srvConsole.print('info', messageInfo);
  }

  getCoreExt() {
    // Obtener unicamente el dominio actual
    let domainCore = this.srvDomain.getDomainCore();
    let domainExt = this.srvDomain.getDomainExt();
    this.domCoreExt = `${domainCore}.${domainExt}`;
    let messageCore = `Core Ext: ${this.domCoreExt}`;
    this.debug && this.srvConsole.print('info', messageCore);
  }

  getUrlHref() {
    let urlDomain = this.domainInfo;
    let urlBase = this.srvBaseUrl.getBaseUrl();
    if (urlBase === '/') { urlBase = ''; }
    this.urlFront = urlDomain + urlBase;
    let message = `URL Front: ${this.urlFront}`;
    this.debug && this.srvConsole.print('info', message);
  }

  dataJson() {
    this.dataStudy = [
      {
        'id': '1',
        'image': `${this.urlFront}/assets/works/external/img/study/png/back-sena.png`,
        'type': 'Tecnologo',
        'career': 'Analisis y Desarrollo de Sistemas de Informacion',
        'institute': 'Sena (Servicio Nacional de Aprendizaje)',
        'dateSince': '2014-07',
        'dateUntil': '2016-06',
        'dateTime': '2 Años',
        'rate': '5.0',
        'link': 'https://oferta.senasofiaplus.edu.co/',
      },
      {
        'id': '2',
        'image': `${this.urlFront}/assets/works/external/img/study/png/back-sena.png`,
        'type': 'Tecnologo',
        'career': 'Analisis y Desarrollo de Software',
        'institute': 'Sena (Servicio Nacional de Aprendizaje)',
        'dateSince': '2022-09',
        'dateUntil': '2024-12',
        'dateTime': '2 Años / 3 Meses',
        'rate': '5.0',
        'link': 'https://oferta.senasofiaplus.edu.co/',
      },
      {
        'id': '3',
        'image': `${this.urlFront}/assets/works/external/img/study/png/back-compensar.png`,
        'type': 'Pregrado',
        'career': 'Ingeniera de Software',
        'institute': 'Fundacion Universitaria de Compensar',
        'dateSince': '2024-01',
        'dateUntil': '2028-12',
        'dateTime': '5 Años',
        'rate': '5.0',
        'link': 'https://ucompensar.edu.co/',
      },
    ];
    this.dataStudy.sort((a, b) => parseInt(b.id) - parseInt(a.id));
    console.log(this.dataStudy);
  }
}
