import { Component, HostListener, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';

import { Environment } from 'src/environments/environment';
import { BaseurlService } from 'src/app/services/functions/baseurl/baseurl.service';
import { DomainService } from 'src/app/services/functions/domain/domain.service';

@Component({
  selector: 'app-ge-ex-navbar',
  templateUrl: './ge-ex-navbar.component.html',
  styleUrls: ['./ge-ex-navbar.component.css']
})
export class GeExNavbarComponent implements OnInit {
  appTitle: string = Environment.title;
  domainMajor: string = '';
  urlFront: string = '';

  isScrolled = false;
  darkTheme = true;

  constructor (
    private renderer: Renderer2,
    private router: Router,
    private serviceBaseUrl: BaseurlService,
    private serviceDomain: DomainService,
  ) {}

  ngOnInit(): void {
    this.getDomain();
    this.getUrlHref();
  }

  getDomain() {
    let dmMajor = this.serviceDomain.getDomainInfo();
    this.domainMajor = dmMajor;
  }

  getUrlHref() {
    let urlDomain = this.domainMajor;
    let urlBase = this.serviceBaseUrl.getBaseUrl();
    if (urlBase === '/') { urlBase = ''; }
    this.urlFront = urlDomain + urlBase;
    //console.log(`urlFront: ${this.urlFront}`);
  }

  getSubDmn(dataSub: any, endpoint: any) {
    let urlSub = this.serviceDomain.getSubOther(
      dataSub, endpoint
    );
    window.open(urlSub, '_blank');
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    this.isScrolled = scrollPosition > 0;
    const htmlElement = document.querySelector('html');

    if (htmlElement) {
      if (this.isScrolled) {
        this.renderer.addClass(document.body, 'scrolled');
        if (htmlElement.getAttribute('data-bs-theme') === 'dark') {
          this.renderer.addClass(document.body, 'dark-theme');
        }
      } else {
        this.renderer.removeClass(document.body, 'scrolled');
        this.renderer.removeClass(document.body, 'dark-theme');
      }
    }
  }
}
