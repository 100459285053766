import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

class CustomError extends Error {
  confirmed: boolean;
  status: string;

  constructor(
    message: string,
    confirmed: boolean,
    status: string
  ) {
    super(message);  // Llama al constructor de Error
    this.name = 'CustomError';  // Personaliza el nombre del error
    this.confirmed = confirmed;
    this.status = status;
  }
}

interface Message {
  confirmed: boolean;
  message: string;
  status: string;
}

@Injectable({
  providedIn: 'root'
})
export class SweetService {
  // allowEscapeKey: Evitar cerrar con la tecla escape
  // allowOutsideClick: Evitar cerrar al hacer clic fuera
  // confirmButtonText: Texto para el botón de confirmación
  // showConfirmButton: Ocultar el botón de confirmación

  constructor() { }

  private button(size: any, color: any) {
    return [
      'btn',
      `btn-${size}`,
      `btn-${color}`,
      'rounded-0',
    ].join(' ');
  }

  close() { Swal.close(); }

  process() {
    Swal.fire({
      icon: 'info',
      title: 'Procesando...',
      text: 'Por favor, espera un momento.',

      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        // Cargar loader animado
        Swal.showLoading();
      }
    });
  }

  service() {
    Swal.fire({
      icon: 'error',
      title: 'Servicio Inaccesible',
      text: 'Intenta nuevamente más tarde.',

      allowEscapeKey: false,
      allowOutsideClick: false,
      confirmButtonText: 'Aceptar',
      showConfirmButton: true,
      customClass: {
        confirmButton: this.button(
          'md', 'danger'
        ),
      },
    }).then((result) => {
      if (result.isConfirmed) {
        console.log('user confirmed');
      }
    });
  }

  denied() {
    Swal.fire({
      icon: 'warning',
      title: 'Acceso Denegado',
      text: 'Permisos insuficientes!',

      allowEscapeKey: false,
      allowOutsideClick: false,
      confirmButtonText: 'Aceptar',
      showConfirmButton: true,
      customClass: {
        confirmButton: this.button(
          'md', 'warning'
        ),
      },
    });
  }

  async response(information: any) {
    // Extraer el mensaje de la respuesta
    const message = information.response.message;
    // Extraer el estado de la respuesta
    const status = information.response.status;
    // Definir un mapa de estados a títulos
    const titles = new Map([
      ['error', 'Error'],
      ['info', 'Importante'],
      ['success', 'Éxito'],
      ['warning', 'Advertencia'],
    ]);
    // Definir un mapa de estados a títulos
    const buttons = new Map([
      ['error', 'danger'],
      ['info', 'info'],
      ['success', 'success'],
      ['warning', 'warning'],
    ]);
    // Obtener el título según el estado
    const title = titles.get(status) ?? 'Desconocido';
    // Obtener el título según el estado
    const confirm = buttons.get(status) ?? 'primary';
    // Compartir informacion de la respuesta
    return await this.message(title, message, status, confirm);
  }

  message(
    title: any,
    message: any,
    status: any,
    confirm: any
  ): Promise<Message> {
    return new Promise((resolve, reject) => {
      Swal.fire({
        icon: status,
        title: title,
        text: message,

        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonText: 'Aceptar',
        showConfirmButton: true,
        customClass: {
          confirmButton: this.button(
            'md', confirm
          ),
        },
      }).then((result) => {
        if (result.isConfirmed) {
          // Promesa Aceptada
          resolve({
            confirmed: true,
            message: 'Accion Aceptada',
            status: status,
          });
        } else {
          // Crear un error personalizado
          const rejectionError = new CustomError(
            'Acción Rechazada',
            false,
            'error'
          );
          // Rechazar la promesa
          reject(rejectionError);
        }
      });
    });
  }
}
