<div *ngIf="isLoading" class="loading-init">
  <div class="loading-spinner"></div>
</div>

<!-- Container fluid -->
<section class="container-fluid px-0">
  <!-- row -->
  <div class="row g-0">


    <div class="col-xl-10 col-lg-12 col-md-12 col-12">
      <!-- chat list -->
      <div class="chat-body chat-body-dim w-100 vh-100" data-simplebar="">

        <!-- Contenido Titulo -->
        <div class="bg-white border-top border-bottom px-1 py-1 sticky-top cont_title">
          <div class="card smooth-shadow-lg bg-white rounded-0">
            <div class="card-body">
              <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center">
                  <a href="#" class="me-2 d-xl-none d-block" data-close>
                    <i class="fe fe-arrow-left"></i>
                  </a>
                  <div class="avatar avatar-md avatar-indicators avatar-online">
                    <img src="assets/geeks/images/avatar/avatar-4.jpg" alt="" class="rounded-circle" />
                  </div>
                  <!-- media body -->
                  <div class="ms-2">
                    <h4 class="mb-0">Modulo Usuarios</h4>
                    <!--<p class="mb-0">Online</p>-->
                  </div>
                </div>
                <div>
                  <button type="button" class="me-1 rounded-0 btn btn-sm btn-success">
                    <em class="me-1 fe fe-user-plus fs-3"></em>
                    <span>Nuevo</span>
                  </button>
                  <button type="button" class="me-1 rounded-0 btn btn-sm btn-success">
                    <em class="me-1 fe fe-user-plus fs-3"></em>
                    <span>Editar</span>
                  </button>
                  <button type="button" class="me-1 rounded-0 btn btn-sm btn-warning">
                    <em class="me-1 fe fe-user-plus fs-3"></em>
                    <span>Remover</span>
                  </button>
                  <button type="button" class="me-1 rounded-0 btn btn-sm btn-danger">
                    <em class="me-1 fe fe-user-plus fs-3"></em>
                    <span>Eliminar</span>
                  </button>
                  <button type="button" class="me-1 rounded-0 btn btn-sm btn-info">
                    <em class="me-1 fe fe-user-plus fs-3"></em>
                    <span>Filtros</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>


        <!-- Contenido Cuerpo -->
        <div class="px-1 py-1 overflow-auto flex-grow-1">
          <div class="card smooth-shadow-lg bg-white rounded-0">
            <!-- card header  -->
            <div class="card-header">
              <h4 class="mb-1">Data Table With Buttons</h4>
              <p class="mb-0">
                Informacion Importante
              </p>
            </div>
            <!-- table  -->
            <div class="card-body">
              <div class="table-card">
                <div class="table-responsive table-wrapper">
                  <table class="table table-bordered table-sm table-hover table-striped w-100">
                    <thead class="table-success">
                      <tr>
                        <!-- Encabezados de la tabla -->
                        <th *ngFor="let header of pageColumn">
                          {{ header }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let data of pageData">
                        <td *ngFor="let header of pageColumn">
                          {{ data[header] }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>


        <!-- Contenido Footer -->
        <div class="bg-light px-0 py-0 chat_footer chat-fixed mt-auto">
          <div class="card smooth-shadow-lg bg-white rounded-0">
            <div class="card-body">
              <div class="p-0">
                contenido
              </div>
              <!--<div class="bg-white p-0 rounded-3 shadow-sm d-none">
                <form>
                  <div class="position-relative">
                    <textarea class="form-control border-0 form-control-simple no-resize" placeholder="Type a New Message" rows="1"></textarea>
                  </div>
                  <div class="position-absolute end-0 mt-n7 me-4">
                    <button type="submit" class="fs-3 btn text-primary btn-focus-none" title="Enviar">
                      <i class="fe fe-send"></i>
                    </button>
                  </div>
                </form>
              </div>
              <div class="mt-3 d-flex">
                <div>
                  <a href="#" class="text-link me-2 fs-4">
                    <i class="bi-emoji-smile"></i>
                  </a>
                  <a href="#" class="text-link me-2 fs-4">
                    <i class="bi-paperclip"></i>
                  </a>
                  <a href="#" class="text-link me-3 fs-4">
                    <i class="bi-mic"></i>
                  </a>
                </div>
                <div class="dropdown">
                  <a href="#" class="text-link fs-4" id="moreAction" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="fe fe-more-horizontal"></i>
                  </a>
                  <div class="dropdown-menu" aria-labelledby="moreAction">
                    <a class="dropdown-item" href="#">Action</a>
                    <a class="dropdown-item" href="#">Another action</a>
                    <a class="dropdown-item" href="#">Something else here</a>
                  </div>
                </div>
              </div>-->
            </div>
          </div>
        </div>



      </div>
    </div>


    <div class="col-xl-2 col-lg-12 col-md-12 col-12">
      <div class="card smooth-shadow-lg bg-white rounded-0">
        <div class="card-body">
          <div class="bg-white border-end border-top vh-100 p-0">
            <!-- chat list -->
            <div class="chat-window">
              <div class="chat-sticky-header sticky-top bg-white">
                <div class="px-0 pt-0 pb-0">
                  <!-- heading -->
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <h1 class="mb-0 fw-bold h2">
                        <span>Filtrar Datos</span>
                      </h1>
                    </div>
                    <!-- new chat dropdown -->
                    <!--<div>
                      <a href="#" class="btn btn-primary rounded-circle btn-icon texttooltip" data-template="newchat" data-bs-toggle="modal" data-bs-target="#newchatModal">
                        <i class="fe fe-edit"></i>
                        <div id="newchat" class="d-none">
                          <span>New Chat</span>
                        </div>
                      </a>
                      <span class="dropdown">
                        <a href="#" class="btn btn-light btn-icon rounded-circle" id="settingLink" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="fe fe-settings"></i>
                        </a>
                        <span class="dropdown-menu" aria-labelledby="settingLink">
                          <a class="dropdown-item" href="#">Setting</a>
                          <a class="dropdown-item" href="#">Help and Feedback</a>
                        </span>
                      </span>
                    </div>-->
                  </div>
                  <!-- search -->
                  <div class="mt-4 mb-4">
                    <input type="search" class="form-control form-control-sm" placeholder="Search people, group and messages" />
                  </div>
                  <!-- User chat -->

                  <div class="d-flex justify-content-between align-items-center">
                    <!-- media -->
                    <a href="#" class="text-link">
                      <div class="d-flex">
                        <div class="avatar avatar-md avatar-indicators avatar-online">
                          <img src="assets/geeks/images/avatar/avatar-1.jpg" alt="" class="rounded-circle" />
                        </div>
                        <!-- media body -->
                        <div class="ms-2">
                          <h5 class="mb-0">Jitu Chauhan</h5>
                          <p class="mb-0">Online</p>
                        </div>
                      </div>
                    </a>
                    <!-- dropdown -->
                    <div class="dropdown dropstart">
                      <a href="#" class="text-link" id="userSetting" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fe fe-more-horizontal fs-3"></i>
                      </a>
                      <ul class="dropdown-menu" aria-labelledby="userSetting">
                        <li class="dropdown-animation dropdown-submenu dropdown-toggle-none">
                          <a class="dropdown-item dropdown-toggle" href="#" aria-haspopup="true" aria-expanded="false" data-bs-toggle="dropdown">
                            <i class="fe fe-circle dropdown-item-icon"></i>
                            Status
                          </a>
                          <ul class="dropdown-menu dropdown-menu-xs">
                            <li>
                              <a class="dropdown-item" href="#">
                                <span class="badge-dot bg-success me-2"></span>
                                Online
                              </a>
                            </li>
                            <li>
                              <a class="dropdown-item" href="#">
                                <span class="badge-dot bg-secondary me-2"></span>
                                Offline
                              </a>
                            </li>
                            <li>
                              <a class="dropdown-item" href="#">
                                <span class="badge-dot bg-warning me-2"></span>
                                Away
                              </a>
                            </li>
                            <li>
                              <a class="dropdown-item" href="#">
                                <span class="badge-dot bg-danger me-2"></span>
                                Busy
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <a class="dropdown-item" href="#">
                            <i class="fe fe-settings dropdown-item-icon"></i>
                            Setting
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="#">
                            <i class="fe fe-user dropdown-item-icon"></i>
                            Profile
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="#">
                            <i class="fe fe-power dropdown-item-icon"></i>
                            Sign Out
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <!-- nav tabs-->

                <ul class="nav nav-line-bottom" id="tab" role="tablist">
                  <!-- nav item -->
                  <li class="nav-item">
                    <a class="nav-link active py-2" id="recent-tab" data-bs-toggle="pill" href="#recent" role="tab" aria-controls="recent" aria-selected="true">Recent</a>
                  </li>
                  <!-- nav item -->
                  <li class="nav-item">
                    <a class="nav-link py-2" id="contact-tab" data-bs-toggle="pill" href="#contact" role="tab" aria-controls="contact" aria-selected="true">Contact</a>
                  </li>
                </ul>
              </div>
              <div data-simplebar style="height: 100vh; overflow: auto">
                <!-- tab content -->
                <div class="tab-content" id="tabContent">
                  <!-- tab pane -->
                  <div class="tab-pane fade show active" id="recent" role="tabpanel" aria-labelledby="recent-tab">
                    <!-- contact list -->
                    <ul class="list-unstyled contacts-list">
                      <!-- contact item -->
                      <li class="py-3 px-4 chat-item contacts-item">
                        <!-- contact link -->

                        <div class="d-flex justify-content-between align-items-center">
                          <!-- media -->
                          <a href="#" class="text-link contacts-link">
                            <div class="d-flex">
                              <div class="avatar avatar-md avatar-indicators avatar-online">
                                <img src="assets/geeks/images/avatar/avatar-2.jpg" alt="" class="rounded-circle" />
                              </div>
                              <!-- media body -->
                              <div class="ms-2">
                                <h5 class="mb-0 fw-bold">Olivia Cooper</h5>
                                <p class="mb-0 text-truncate">I m for unread message components...</p>
                              </div>
                            </div>
                          </a>
                          <div>
                            <!-- icon -->
                            <small>8:48AM</small>
                            <div class="text-end">
                              <span class="icon-shape icon-xs text-white bg-danger rounded-circle fw-bold fs-6">1</span>
                            </div>
                          </div>
                        </div>
                        <!-- chat action -->
                        <div class="chat-actions">
                          <!-- dropdown -->
                          <div class="dropdown dropstart">
                            <a
                              href="#"
                              class="btn btn-white btn-icon btn-sm rounded-circle primary-hover"
                              id="dropdownMenuButton2"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false">
                              <i class="fe fe-more-horizontal fs-3"></i>
                            </a>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                              <a class="dropdown-item" href="#">
                                <i class="bi-pin-angle dropdown-item-icon"></i>
                                Pin
                              </a>
                              <a class="dropdown-item" href="#">
                                <i class="bi-person-x dropdown-item-icon"></i>
                                Mute
                              </a>
                              <a class="dropdown-item" href="#">
                                <i class="bi-eye-slash dropdown-item-icon"></i>
                                Hide
                              </a>
                              <a class="dropdown-item" href="#">
                                <i class="bi-person-plus dropdown-item-icon"></i>
                                Add to Favorite
                              </a>
                            </div>
                          </div>
                        </div>
                      </li>
                      <!-- chat item -->
                      <li class="bg-light py-3 px-4 chat-item contacts-item">
                        <div class="d-flex justify-content-between align-items-center">
                          <a href="#" class="text-link contacts-link">
                            <!-- media -->
                            <div class="d-flex">
                              <div class="avatar avatar-md avatar-indicators avatar-online">
                                <img src="assets/geeks/images/avatar/avatar-4.jpg" alt="" class="rounded-circle" />
                              </div>
                              <!-- media body -->
                              <div class="ms-2">
                                <h5 class="mb-0">Sharad Mishra</h5>
                                <p class="mb-0">Currently chat with user components...</p>
                              </div>
                            </div>
                          </a>
                          <div>
                            <small>8:48AM</small>
                          </div>
                        </div>
                        <!-- chat actions -->
                        <div class="chat-actions">
                          <!-- dropdown -->
                          <div class="dropdown dropstart">
                            <a
                              href="#"
                              class="btn btn-white btn-icon btn-sm rounded-circle primary-hover"
                              id="dropdownMenuButton3"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false">
                              <i class="fe fe-more-horizontal fs-3"></i>
                            </a>
                            <!-- dropdown menu -->
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                              <a class="dropdown-item" href="#">
                                <i class="bi-pin-angle dropdown-item-icon"></i>
                                Pin
                              </a>
                              <a class="dropdown-item" href="#">
                                <i class="bi-person-x dropdown-item-icon"></i>
                                Mute
                              </a>
                              <a class="dropdown-item" href="#">
                                <i class="bi-eye-slash dropdown-item-icon"></i>
                                Hide
                              </a>
                              <a class="dropdown-item" href="#">
                                <i class="bi-person-plus dropdown-item-icon"></i>
                                Add to Favorite
                              </a>
                            </div>
                          </div>
                        </div>
                      </li>
                      <!-- chat item -->
                      <li class="py-3 px-4 chat-item contacts-item">
                        <div class="d-flex justify-content-between align-items-center">
                          <a href="#" class="text-link contacts-link">
                            <!-- media -->
                            <div class="d-flex">
                              <div class="avatar avatar-md avatar-indicators avatar-online">
                                <img src="assets/geeks/images/avatar/avatar-3.jpg" alt="" class="rounded-circle" />
                              </div>
                              <!-- media body -->
                              <div class="ms-2">
                                <h5 class="mb-0">Tanya Davies</h5>
                                <img src="assets/geeks/images/png/dot-move.png" alt="" class="ms-1" />
                              </div>
                            </div>
                          </a>
                          <div>
                            <small>8:48AM</small>
                          </div>
                        </div>
                        <!-- chat actions -->
                        <div class="chat-actions">
                          <!-- dropdown  -->
                          <div class="dropdown dropstart">
                            <a
                              href="#"
                              class="btn btn-white btn-icon btn-sm rounded-circle primary-hover"
                              id="dropdownMenuButton4"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false">
                              <i class="fe fe-more-horizontal fs-3"></i>
                            </a>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton4">
                              <a class="dropdown-item" href="#">
                                <i class="bi-pin-angle dropdown-item-icon"></i>
                                Pin
                              </a>
                              <a class="dropdown-item" href="#">
                                <i class="bi-person-x dropdown-item-icon"></i>
                                Mute
                              </a>
                              <a class="dropdown-item" href="#">
                                <i class="bi-eye-slash dropdown-item-icon"></i>
                                Hide
                              </a>
                              <a class="dropdown-item" href="#">
                                <i class="bi-person-plus dropdown-item-icon"></i>
                                Add to Favorite
                              </a>
                            </div>
                          </div>
                        </div>
                      </li>
                      <!-- chat item -->
                      <li class="py-3 px-4 chat-item contacts-item">
                        <div class="d-flex justify-content-between align-items-center">
                          <a href="#" class="text-link contacts-link">
                            <!-- media -->
                            <div class="d-flex">
                              <div class="avatar avatar-md avatar-indicators avatar-online">
                                <img src="assets/geeks/images/avatar/avatar-6.jpg" alt="" class="rounded-circle" />
                              </div>
                              <div class="avatar avatar-sm avatar-primary position-absolute mt-3 ms-n2">
                                <span class="avatar-initials rounded-circle fs-6">GK</span>
                              </div>
                              <!-- media body -->
                              <div class="ms-2">
                                <h5 class="mb-0">Figma Design Group</h5>
                                <p class="mb-0">Designer keep foucs on design...</p>
                              </div>
                            </div>
                          </a>
                          <div>
                            <small>3/11/2021</small>
                          </div>
                        </div>
                        <!-- chat actions -->
                        <div class="chat-actions">
                          <div class="dropdown dropstart">
                            <a
                              href="#"
                              class="btn btn-white btn-icon btn-sm rounded-circle primary-hover"
                              id="dropdownMenuButton5"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false">
                              <i class="fe fe-more-horizontal fs-3"></i>
                            </a>
                            <!-- dropdown menu -->
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton5">
                              <a class="dropdown-item" href="#">
                                <i class="bi-pin-angle dropdown-item-icon"></i>
                                Pin
                              </a>
                              <a class="dropdown-item" href="#">
                                <i class="bi-person-x dropdown-item-icon"></i>
                                Mute
                              </a>
                              <a class="dropdown-item" href="#">
                                <i class="bi-eye-slash dropdown-item-icon"></i>
                                Hide
                              </a>
                              <a class="dropdown-item" href="#">
                                <i class="bi-person-plus dropdown-item-icon"></i>
                                Add to Favorite
                              </a>
                            </div>
                          </div>
                        </div>
                      </li>
                      <!-- chat item -->
                      <li class="py-3 px-4 chat-item contacts-item">
                        <div class="d-flex justify-content-between align-items-center">
                          <a href="#" class="text-link contacts-link">
                            <!-- media -->
                            <div class="d-flex">
                              <div class="avatar avatar-md avatar-indicators avatar-away">
                                <img src="assets/geeks/images/avatar/avatar-5.jpg" alt="" class="rounded-circle" />
                              </div>
                              <!-- media body -->
                              <div class="ms-2">
                                <h5 class="mb-0">Pete Martin</h5>
                                <p class="mb-0">On going description of group...</p>
                              </div>
                            </div>
                          </a>
                          <div>
                            <small>2/10/2021</small>
                          </div>
                        </div>
                        <!-- chat actions -->
                        <div class="chat-actions">
                          <!-- dropdown -->
                          <div class="dropdown dropstart">
                            <a
                              href="#"
                              class="btn btn-white btn-icon btn-sm rounded-circle primary-hover"
                              id="dropdownMenuButton6"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false">
                              <i class="fe fe-more-horizontal fs-3"></i>
                            </a>
                            <!-- dropdown menu -->
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton6">
                              <a class="dropdown-item" href="#">
                                <i class="bi-pin-angle dropdown-item-icon"></i>
                                Pin
                              </a>
                              <a class="dropdown-item" href="#">
                                <i class="bi-person-x dropdown-item-icon"></i>
                                Mute
                              </a>
                              <a class="dropdown-item" href="#">
                                <i class="bi-eye-slash dropdown-item-icon"></i>
                                Hide
                              </a>
                              <a class="dropdown-item" href="#">
                                <i class="bi-person-plus dropdown-item-icon"></i>
                                Add to Favorite
                              </a>
                            </div>
                          </div>
                        </div>
                      </li>
                      <!-- chat item -->
                      <li class="py-3 px-4 chat-item contacts-item">
                        <div class="d-flex justify-content-between align-items-center">
                          <a href="#" class="text-link contacts-link">
                            <!-- media -->
                            <div class="d-flex">
                              <div class="avatar avatar-md avatar-indicators avatar-offline">
                                <img src="assets/geeks/images/avatar/avatar-9.jpg" alt="" class="rounded-circle" />
                              </div>
                              <!-- media body -->
                              <div class="ms-2">
                                <h5 class="mb-0">Olivia Cooper</h5>
                                <p class="mb-0">On going description of group...</p>
                              </div>
                            </div>
                          </a>
                          <div>
                            <small>2/3/2021</small>
                          </div>
                        </div>
                        <!-- chat actions -->
                        <div class="chat-actions">
                          <!-- dropdown  -->
                          <div class="dropdown dropstart">
                            <a
                              href="#"
                              class="btn btn-white btn-icon btn-sm rounded-circle primary-hover"
                              id="dropdownMenuButton7"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false">
                              <i class="fe fe-more-horizontal fs-3"></i>
                            </a>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton7">
                              <a class="dropdown-item" href="#">
                                <i class="bi-pin-angle dropdown-item-icon"></i>
                                Pin
                              </a>
                              <a class="dropdown-item" href="#">
                                <i class="bi-person-x dropdown-item-icon"></i>
                                Mute
                              </a>
                              <a class="dropdown-item" href="#">
                                <i class="bi-eye-slash dropdown-item-icon"></i>
                                Hide
                              </a>
                              <a class="dropdown-item" href="#">
                                <i class="bi-person-plus dropdown-item-icon"></i>
                                Add to Favorite
                              </a>
                            </div>
                          </div>
                        </div>
                      </li>
                      <!-- chat item -->
                      <li class="py-3 px-4 chat-item contacts-item">
                        <div class="d-flex justify-content-between align-items-center">
                          <a href="#" class="text-link contacts-link">
                            <!-- media -->
                            <div class="d-flex">
                              <div class="avatar avatar-md avatar-indicators avatar-busy">
                                <img src="assets/geeks/images/avatar/avatar-19.jpg" alt="" class="rounded-circle" />
                              </div>
                              <!-- media body -->
                              <div class="ms-2">
                                <h5 class="mb-0">Jamarcus Streich</h5>
                                <p class="mb-0">Start design system for UI.</p>
                              </div>
                            </div>
                          </a>
                          <div>
                            <small>1/24/2021</small>
                          </div>
                        </div>
                        <!-- chat actions -->
                        <div class="chat-actions">
                          <!-- dropdown -->
                          <div class="dropdown dropstart">
                            <a
                              href="#"
                              class="btn btn-white btn-icon btn-sm rounded-circle primary-hover"
                              id="dropdownMenuButton8"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false">
                              <i class="fe fe-more-horizontal fs-3"></i>
                            </a>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton8">
                              <a class="dropdown-item" href="#">
                                <i class="bi-pin-angle dropdown-item-icon"></i>
                                Pin
                              </a>
                              <a class="dropdown-item" href="#">
                                <i class="bi-person-x dropdown-item-icon"></i>
                                Mute
                              </a>
                              <a class="dropdown-item" href="#">
                                <i class="bi-eye-slash dropdown-item-icon"></i>
                                Hide
                              </a>
                              <a class="dropdown-item" href="#">
                                <i class="bi-person-plus dropdown-item-icon"></i>
                                Add to Favorite
                              </a>
                            </div>
                          </div>
                        </div>
                      </li>
                      <!-- chat item -->
                      <li class="py-3 px-4 chat-item contacts-item">
                        <div class="d-flex justify-content-between align-items-center">
                          <a href="#" class="text-link contacts-link">
                            <!-- media -->
                            <div class="d-flex">
                              <div class="avatar avatar-md avatar-indicators avatar-busy">
                                <img src="assets/geeks/images/avatar/avatar-12.jpg" alt="" class="rounded-circle" />
                              </div>
                              <!-- media body -->
                              <div class="ms-2">
                                <h5 class="mb-0">Lauren Wilson</h5>
                                <p class="mb-0">Start design system for UI...</p>
                              </div>
                            </div>
                          </a>
                          <div>
                            <small>3/3/2021</small>
                          </div>
                        </div>
                        <!-- chat actions -->
                        <div class="chat-actions">
                          <!-- dropdown -->
                          <div class="dropdown dropstart">
                            <a
                              href="#"
                              class="btn btn-white btn-icon btn-sm rounded-circle primary-hover"
                              id="dropdownMenuButton9"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false">
                              <i class="fe fe-more-horizontal fs-3"></i>
                            </a>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton9">
                              <a class="dropdown-item" href="#">
                                <i class="bi-pin-angle dropdown-item-icon"></i>
                                Pin
                              </a>
                              <a class="dropdown-item" href="#">
                                <i class="bi-person-x dropdown-item-icon"></i>
                                Mute
                              </a>
                              <a class="dropdown-item" href="#">
                                <i class="bi-eye-slash dropdown-item-icon"></i>
                                Hide
                              </a>
                              <a class="dropdown-item" href="#">
                                <i class="bi-person-plus dropdown-item-icon"></i>
                                Add to Favorite
                              </a>
                            </div>
                          </div>
                        </div>
                      </li>
                      <!-- chat item -->
                      <li class="py-3 px-4 chat-item contacts-item">
                        <div class="d-flex justify-content-between align-items-center">
                          <a href="#" class="text-link contacts-link">
                            <!-- media -->
                            <div class="d-flex">
                              <div class="avatar avatar-md avatar-indicators avatar-online">
                                <img src="assets/geeks/images/avatar/avatar-14.jpg" alt="" class="rounded-circle" />
                              </div>
                              <!-- media body -->
                              <div class="ms-2">
                                <h5 class="mb-0">User Name</h5>
                                <p class="mb-0">On going description of group..</p>
                              </div>
                            </div>
                          </a>
                          <div>
                            <small>1/5/2021</small>
                          </div>
                        </div>
                        <!-- chat actions -->
                        <div class="chat-actions">
                          <!-- dropdown -->
                          <div class="dropdown dropstart">
                            <a
                              href="#"
                              class="btn btn-white btn-icon btn-sm rounded-circle primary-hover"
                              id="dropdownMenuButton10"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false">
                              <i class="fe fe-more-horizontal fs-3"></i>
                            </a>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton10">
                              <a class="dropdown-item" href="#">
                                <i class="bi-pin-angle dropdown-item-icon"></i>
                                Pin
                              </a>
                              <a class="dropdown-item" href="#">
                                <i class="bi-person-x dropdown-item-icon"></i>
                                Mute
                              </a>
                              <a class="dropdown-item" href="#">
                                <i class="bi-eye-slash dropdown-item-icon"></i>
                                Hide
                              </a>
                              <a class="dropdown-item" href="#">
                                <i class="bi-person-plus dropdown-item-icon"></i>
                                Add to Favorite
                              </a>
                            </div>
                          </div>
                        </div>
                      </li>
                      <!-- chat item -->
                      <li class="py-3 px-4 chat-item contacts-item">
                        <div class="d-flex justify-content-between align-items-center">
                          <a href="#" class="text-link contacts-link">
                            <!-- media -->
                            <div class="d-flex">
                              <div class="avatar avatar-md avatar-indicators avatar-online">
                                <img src="assets/geeks/images/avatar/avatar-15.jpg" alt="" class="rounded-circle" />
                              </div>
                              <!-- media body -->
                              <div class="ms-2">
                                <h5 class="mb-0">Rosalee Roberts</h5>
                                <p class="mb-0">On going description of group..</p>
                              </div>
                            </div>
                          </a>
                          <div>
                            <small>1/14/2021</small>
                          </div>
                        </div>
                        <!-- chat actions -->
                        <div class="chat-actions">
                          <!--  dropdown -->
                          <div class="dropdown dropstart">
                            <a
                              href="#"
                              class="btn btn-white btn-icon btn-sm rounded-circle primary-hover"
                              id="dropdownMenuButton11"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false">
                              <i class="fe fe-more-horizontal fs-3"></i>
                            </a>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton11">
                              <a class="dropdown-item" href="#">
                                <i class="bi-pin-angle dropdown-item-icon"></i>
                                Pin
                              </a>
                              <a class="dropdown-item" href="#">
                                <i class="bi-person-x dropdown-item-icon"></i>
                                Mute
                              </a>
                              <a class="dropdown-item" href="#">
                                <i class="bi-eye-slash dropdown-item-icon"></i>
                                Hide
                              </a>
                              <a class="dropdown-item" href="#">
                                <i class="bi-person-plus dropdown-item-icon"></i>
                                Add to Favorite
                              </a>
                            </div>
                          </div>
                        </div>
                      </li>
                      <!-- chat item -->
                      <li class="py-3 px-4 chat-item contacts-item">
                        <div class="d-flex justify-content-between align-items-center">
                          <a href="#" class="text-link contacts-link">
                            <!-- media -->
                            <div class="d-flex">
                              <div class="avatar avatar-md avatar-indicators avatar-busy">
                                <img src="assets/geeks/images/avatar/avatar-12.jpg" alt="" class="rounded-circle" />
                              </div>
                              <!-- media body -->
                              <div class="ms-2">
                                <h5 class="mb-0">Lauren Wilson</h5>
                                <p class="mb-0">Start design system for UI...</p>
                              </div>
                            </div>
                          </a>
                          <div>
                            <small>2/12/2021</small>
                          </div>
                        </div>
                        <!-- chat actions -->
                        <div class="chat-actions">
                          <div class="dropdown dropstart">
                            <a
                              href="#"
                              class="btn btn-white btn-icon btn-sm rounded-circle primary-hover"
                              id="dropdownMenuButton12"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false">
                              <i class="fe fe-more-horizontal fs-3"></i>
                            </a>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton12">
                              <a class="dropdown-item" href="#">
                                <i class="bi-pin-angle dropdown-item-icon"></i>
                                Pin
                              </a>
                              <a class="dropdown-item" href="#">
                                <i class="bi-person-x dropdown-item-icon"></i>
                                Mute
                              </a>
                              <a class="dropdown-item" href="#">
                                <i class="bi-eye-slash dropdown-item-icon"></i>
                                Hide
                              </a>
                              <a class="dropdown-item" href="#">
                                <i class="bi-person-plus dropdown-item-icon"></i>
                                Add to Favorite
                              </a>
                            </div>
                          </div>
                        </div>
                      </li>
                      <!-- chat item -->
                      <li class="py-3 px-4 chat-item contacts-item">
                        <div class="d-flex justify-content-between align-items-center">
                          <a href="#" class="text-link contacts-link">
                            <!-- media -->
                            <div class="d-flex">
                              <div class="avatar avatar-md avatar-indicators avatar-online">
                                <img src="assets/geeks/images/avatar/avatar-14.jpg" alt="" class="rounded-circle" />
                              </div>
                              <!-- media body -->
                              <div class="ms-2">
                                <h5 class="mb-0">User Name</h5>
                                <p class="mb-0">On going description of group..</p>
                              </div>
                            </div>
                          </a>
                          <div>
                            <small>3/5/2021</small>
                          </div>
                        </div>
                        <!-- chat actions -->
                        <div class="chat-actions">
                          <!-- dropdown -->
                          <div class="dropdown dropstart">
                            <a
                              href="#"
                              class="btn btn-white btn-icon btn-sm rounded-circle primary-hover"
                              id="dropdownMenuButton13"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false">
                              <i class="fe fe-more-horizontal fs-3"></i>
                            </a>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton13">
                              <a class="dropdown-item" href="#">
                                <i class="bi-pin-angle dropdown-item-icon"></i>
                                Pin
                              </a>
                              <a class="dropdown-item" href="#">
                                <i class="bi-person-x dropdown-item-icon"></i>
                                Mute
                              </a>
                              <a class="dropdown-item" href="#">
                                <i class="bi-eye-slash dropdown-item-icon"></i>
                                Hide
                              </a>
                              <a class="dropdown-item" href="#">
                                <i class="bi-person-plus dropdown-item-icon"></i>
                                Add to Favorite
                              </a>
                            </div>
                          </div>
                        </div>
                      </li>
                      <!-- chat item -->
                      <li class="py-3 px-4 chat-item contacts-item">
                        <div class="d-flex justify-content-between align-items-center">
                          <a href="#" class="text-link contacts-link">
                            <!-- media -->
                            <div class="d-flex">
                              <div class="avatar avatar-md avatar-indicators avatar-online">
                                <img src="assets/geeks/images/avatar/avatar-15.jpg" alt="" class="rounded-circle" />
                              </div>
                              <!-- media body -->
                              <div class="ms-2">
                                <h5 class="mb-0">Rosalee Roberts</h5>
                                <p class="mb-0">On going description of group..</p>
                              </div>
                            </div>
                          </a>
                          <div>
                            <small>2/7/2021</small>
                          </div>
                        </div>
                        <!-- chat actions -->
                        <div class="chat-actions">
                          <!-- dropdown -->
                          <div class="dropdown dropstart">
                            <a
                              href="#"
                              class="btn btn-white btn-icon btn-sm rounded-circle primary-hover"
                              id="dropdownMenuButton14"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false">
                              <i class="fe fe-more-horizontal fs-3"></i>
                            </a>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton14">
                              <a class="dropdown-item" href="#">
                                <i class="bi-pin-angle dropdown-item-icon"></i>
                                Pin
                              </a>
                              <a class="dropdown-item" href="#">
                                <i class="bi-person-x dropdown-item-icon"></i>
                                Mute
                              </a>
                              <a class="dropdown-item" href="#">
                                <i class="bi-eye-slash dropdown-item-icon"></i>
                                Hide
                              </a>
                              <a class="dropdown-item" href="#">
                                <i class="bi-person-plus dropdown-item-icon"></i>
                                Add to Favorite
                              </a>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <!-- tab pane -->
                  <div class="tab-pane" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                    <ul class="list-unstyled">
                      <!-- list -->
                      <li>
                        <div class="bg-light py-1 px-4 border-bottom fw-semibold">F</div>
                        <a href="#" class="text-link">
                          <div class="d-flex justify-content-between align-items-center py-3 px-4 border-bottom">
                            <!-- media -->
                            <div class="d-flex position-relative">
                              <div class="avatar avatar-md avatar-indicators avatar-online">
                                <img src="assets/geeks/images/avatar/avatar-2.jpg" alt="" class="rounded-circle" />
                              </div>

                              <!-- media body -->
                              <div class="ms-2">
                                <h5 class="mb-0">Fatima Darbar</h5>
                                <p class="mb-0 text-truncate">Online</p>
                              </div>
                            </div>
                          </div>
                          <div class="d-flex justify-content-between align-items-center py-3 px-4">
                            <!-- media -->
                            <div class="d-flex position-relative">
                              <div class="avatar avatar-md avatar-indicators avatar-offline">
                                <img src="assets/geeks/images/avatar/avatar-6.jpg" alt="" class="rounded-circle" />
                              </div>
                              <div class="avatar avatar-sm avatar-primary position-absolute mt-3 ms-n2">
                                <span class="avatar-initials rounded-circle fs-6">GK</span>
                              </div>
                              <!-- media body -->
                              <div class="ms-2">
                                <h5 class="mb-0">Figma Design Group</h5>
                                <p class="mb-0 text-truncate">Offline</p>
                              </div>
                            </div>
                          </div>
                        </a>
                      </li>
                      <!-- list -->
                      <li>
                        <div class="bg-light py-1 px-4 border-bottom border-top fw-semibold text-dark">J</div>
                        <a href="#" class="text-link">
                          <div class="d-flex justify-content-between align-items-center py-3 px-4 border-bottom">
                            <!-- media -->
                            <div class="d-flex">
                              <div class="avatar avatar-md avatar-indicators avatar-away">
                                <img src="assets/geeks/images/avatar/avatar-19.jpg" alt="" class="rounded-circle" />
                              </div>
                              <!-- media body -->
                              <div class="ms-2">
                                <h5 class="mb-0">Jamarcus Streich</h5>
                                <p class="mb-0 text-truncate">Away</p>
                              </div>
                            </div>
                          </div>
                          <div class="d-flex justify-content-between align-items-center py-3 px-4">
                            <!-- media -->
                            <div class="d-flex">
                              <div class="avatar avatar-md avatar-indicators avatar-away">
                                <img src="assets/geeks/images/avatar/avatar-21.jpg" alt="" class="rounded-circle" />
                              </div>
                              <!-- media body -->
                              <div class="ms-2">
                                <h5 class="mb-0">Jasmin Poicha</h5>
                                <p class="mb-0 text-truncate">Away</p>
                              </div>
                            </div>
                          </div>
                        </a>
                      </li>
                      <!-- list -->
                      <li>
                        <div class="bg-light py-1 px-4 border-bottom border-top fw-semibold text-dark">O</div>
                        <a href="#" class="text-link">
                          <div class="d-flex justify-content-between align-items-center py-3 px-4 border-bottom">
                            <!-- media -->
                            <div class="d-flex">
                              <div class="avatar avatar-md avatar-indicators avatar-online">
                                <img src="assets/geeks/images/avatar/avatar-2.jpg" alt="" class="rounded-circle" />
                              </div>
                              <!-- media body -->
                              <div class="ms-2">
                                <h5 class="mb-0">Olivia Cooper</h5>
                                <p class="mb-0 text-truncate">Feeling Happy</p>
                              </div>
                            </div>
                          </div>
                          <div class="d-flex justify-content-between align-items-center py-3 px-4">
                            <!-- media -->
                            <div class="d-flex">
                              <div class="avatar avatar-md avatar-indicators avatar-busy">
                                <img src="assets/geeks/images/avatar/avatar-12.jpg" alt="" class="rounded-circle" />
                              </div>
                              <!-- media body -->
                              <div class="ms-2">
                                <h5 class="mb-0">Oswal Baug</h5>
                                <p class="mb-0 text-truncate">Busy</p>
                              </div>
                            </div>
                          </div>
                        </a>
                      </li>
                      <!-- list -->
                      <li>
                        <div class="bg-light py-1 px-4 border-bottom border-top fw-semibold text-dark">P</div>
                        <a href="#" class="text-link">
                          <div class="d-flex justify-content-between align-items-center py-3 px-4">
                            <!-- media -->
                            <div class="d-flex">
                              <div class="avatar avatar-md avatar-indicators avatar-online">
                                <img src="assets/geeks/images/avatar/avatar-5.jpg" alt="" class="rounded-circle" />
                              </div>
                              <!-- media body -->
                              <div class="ms-2">
                                <h5 class="mb-0">Pete Martin</h5>
                                <p class="mb-0 text-truncate">Online</p>
                              </div>
                            </div>
                          </div>
                        </a>
                        <a href="#" class="text-link">
                          <div class="d-flex justify-content-between align-items-center py-3 px-4">
                            <!-- media -->
                            <div class="d-flex">
                              <div class="avatar avatar-md avatar-indicators avatar-online">
                                <img src="assets/geeks/images/avatar/avatar-11.jpg" alt="" class="rounded-circle" />
                              </div>
                              <!-- media body -->
                              <div class="ms-2">
                                <h5 class="mb-0">Kancha China</h5>
                                <p class="mb-0 text-truncate">Offline</p>
                              </div>
                            </div>
                          </div>
                        </a>
                      </li>
                      <!-- list -->
                      <li>
                        <div class="bg-light py-1 px-4 border-bottom border-top fw-semibold text-dark">S</div>
                        <a href="#" class="text-link">
                          <div class="d-flex justify-content-between align-items-center py-3 px-4">
                            <!-- media -->
                            <div class="d-flex">
                              <div class="avatar avatar-md avatar-indicators avatar-online">
                                <img src="assets/geeks/images/avatar/avatar-4.jpg" alt="" class="rounded-circle" />
                              </div>
                              <!-- media body -->
                              <div class="ms-2">
                                <h4 class="mb-0">Sharad Mishra</h4>
                                <p class="mb-0 text-truncate">Busy</p>
                              </div>
                            </div>
                          </div>
                        </a>
                        <a href="#" class="text-link">
                          <div class="d-flex justify-content-between align-items-center py-3 px-4">
                            <!-- media -->
                            <div class="d-flex">
                              <div class="avatar avatar-md avatar-indicators avatar-online">
                                <img src="assets/geeks/images/avatar/avatar-8.jpg" alt="" class="rounded-circle" />
                              </div>
                              <!-- media body -->
                              <div class="ms-2">
                                <h5 class="mb-0">Sarita Nigam</h5>
                                <p class="mb-0 text-truncate">Busy</p>
                              </div>
                            </div>
                          </div>
                        </a>
                      </li>
                      <!-- list -->
                      <li>
                        <div class="bg-light py-1 px-4 border-bottom border-top fw-semibold text-dark">T</div>
                        <a href="#" class="text-link">
                          <div class="d-flex justify-content-between align-items-center py-3 px-4">
                            <!-- media -->
                            <div class="d-flex">
                              <div class="avatar avatar-md avatar-indicators avatar-online">
                                <img src="assets/geeks/images/avatar/avatar-3.jpg" alt="" class="rounded-circle" />
                              </div>
                              <!-- media body -->
                              <div class="ms-2">
                                <h5 class="mb-0">Tanya Davias</h5>
                                <p class="mb-0 text-truncate">Offline</p>
                              </div>
                            </div>
                          </div>
                        </a>
                        <a href="#" class="text-link">
                          <div class="d-flex justify-content-between align-items-center py-3 px-4">
                            <!-- media -->
                            <div class="d-flex">
                              <div class="avatar avatar-md avatar-indicators avatar-online">
                                <img src="assets/geeks/images/avatar/avatar-5.jpg" alt="" class="rounded-circle" />
                              </div>
                              <!-- media body -->
                              <div class="ms-2">
                                <h5 class="mb-0">Tushar Mishra</h5>
                                <p class="mb-0 text-truncate">Offline</p>
                              </div>
                            </div>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</section>

<!-- Modal: Servicio Inaccesible -->
<div class="modal fade"
id="modalAlertAvailable"
name="modalAlertAvailable"
tabindex="-1" aria-hidden="true"
aria-labelledby="modalAlertData"
data-bs-backdrop="static"
data-bs-keyboard="false">
  <div class="modal-dialog modal-sm modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content rounded-0">
      <div class="modal-header justify-content-center rounded-0 border-0 bg-danger">
        <h4 class="modal-title" id="modalAlertData">
          <span class="text-white">Servicio Inaccesible</span>
        </h4>
      </div>
      <div class="modal-body text-center">
        <p class="text-danger mb-2">
          <span>
            Estamos experimentando problemas con nuestro servicio.
          </span>
        </p>
        <p class="text-dark mb-2">
          <span>
            Nos encontramos trabajando para resolverlo
            y puedas continuar con tu proceso.
            Por favor, inténtalo nuevamente más tarde.
          </span>
        </p>
      </div>
      <div class="modal-footer justify-content-center">
        <button type="button" (click)="redirectMain();"
        class="btn btn-md btn-success rounded-0">
          <span>Aceptar</span>
        </button>
        <button type="button"
        class="btn btn-md btn-primary rounded-0">
          <span>Soporte Técnico</span>
        </button>
      </div>
    </div>
  </div>
</div>
