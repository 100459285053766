<!-- Wrapper -->
<div class="chat-layout" id="db-wrapper">
  <!-- Content Navbar -->
  <app-ge-in-navbar></app-ge-in-navbar>
  <!-- Page Content -->
  <main id="page-content">
    <!-- Content Header -->
    <app-ge-in-header></app-ge-in-header>
    <!-- Content Data -->
    <router-outlet></router-outlet>
  </main>
</div>
