import { Injectable } from '@angular/core';
import { Environment } from 'src/environments/environment';

const alertColor: Record<
  'alert-primary' |
  'alert-success' |
  'alert-danger' |
  'alert-warning' |
  'alert-info',
  string
> = {
  'alert-primary': '#A7C9FF',
  'alert-success': '#85FF48',
  'alert-danger': '#FF4E48',
  'alert-warning': '#FFD048',
  'alert-info': '#48E6FF',
};

@Injectable({
  providedIn: 'root'
})
export class ConsoleService {
  env: any = Environment;
  debug: string = this.env.app.debug;

  constructor() { }

  print(level: any, mssg: any, ground: boolean = false) {
    const levels: Record<string, (
      ground: boolean, mssg: any
    ) => void> = {
      error: this.error.bind(this),
      info: this.info.bind(this),
      sccs: this.sccs.bind(this),
      warn: this.warn.bind(this),
      json: this.json.bind(this),
    };

    if (this.debug && levels[level]) {
      levels[level](ground, mssg);
    }
  }

  gify(ground: any, alert: any, data: any) {
    let mssg = `Unknown alert type: ${alert}`;
    let info = JSON.stringify(data);
    const alertActions: Record<string, (
      ground: any, info: string
    ) => void> = {
      error: this.error.bind(this),
      info: this.info.bind(this),
      sccs: this.sccs.bind(this),
      warn: this.warn.bind(this),
      json: this.json.bind(this),
    };
    const action = alertActions[alert];
    if (action) { action(ground, info); }
    if (!action) { this.error(ground, mssg); }
  }

  error(ground: any, message: any): void {
    this.bootstrap(
      `Error: ${message}`,
      'alert-danger',
      ground
    );
  }

  info(ground: any, message: any): void {
    this.bootstrap(
      `Info: ${message}`,
      'alert-info',
      ground
    );
  }

  json(ground: any, message: any): void {
    let object = JSON.stringify(message);
    this.bootstrap(
      `JSON:` + ' ' + object,
      'alert-primary',
      ground
    );
  }

  sccs(ground: any, message: any): void {
    this.bootstrap(
      `Success: ${message}`,
      'alert-success',
      ground
    );
  }

  warn(ground: any, message: any): void {
    this.bootstrap(
      `Warning: ${message}`,
      'alert-warning',
      ground
    );
  }

  private bootstrap(
    message: string,
    alertClass:
      'alert-primary' |
      'alert-success' |
      'alert-danger' |
      'alert-warning' |
      'alert-info',
    ground: boolean
  ): void {
    // Obtener el color basado en la clase de alerta
    const color = alertColor[alertClass] || '#6c757d';

    // Ajustar valor según necesidades
    const padding = ' '.repeat(100);

    if (ground) {
      // Imprimir el mensaje en la consola
      console.log(
        `%c${message}` + ` ${padding}`, `
          background-color: ${color};
          padding: 5px;
          border-radius: 0px;
          box-sizing: border-box;
          display: block;
        `
      );
    }

    if (!ground) {
      // Imprimir el mensaje en la consola
      console.log(
        `%c${message}`,
        `color: ${color};`
      );
    }

  }
}
