import { Component } from '@angular/core';

@Component({
  selector: 'app-wo-ex-application',
  templateUrl: './wo-ex-application.component.html',
  styleUrls: ['./wo-ex-application.component.css']
})
export class WoExApplicationComponent {

}
