import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseurlService } from 'src/app/services/functions/baseurl/baseurl.service';
import { ConsoleService } from 'src/app/services/functions/console/console.service';
import { DomainService } from 'src/app/services/functions/domain/domain.service';
import { Environment } from 'src/environments/environment';

@Component({
  selector: 'app-wo-te-role-data',
  templateUrl: './wo-te-role-data.component.html',
  styleUrls: ['./wo-te-role-data.component.css']
})
export class WoTeRoleDataComponent implements OnInit {
  env: any = Environment;
  debug: string = this.env.app.debug;
  title: string = this.env.app.title;

  domainInfo: string = '';
  domCoreExt: string = '';
  domainPtcl: string = '';
  urlFront: string = '';

  constructor (
    public router: Router,
    public srvBaseUrl: BaseurlService,
    public srvConsole: ConsoleService,
    public srvDomain: DomainService,
  ) {}

  ngOnInit(): void {
    this.setComponent();
    this.getDomEnv();
    this.getDomain();
    this.getCoreExt();
    this.getUrlHref();
  }

  setComponent() {
    // Obtener el nombre del componente automaticamente
    let information = this.constructor.name;
    let finalData = information.replace('Component', '');
    let message = `Current Component: ${finalData}`;
    this.debug && this.srvConsole.print('warn', message);
  }

  getDomEnv() {
    let domainCheck = this.srvDomain.getDomainVerify();
    let mssgVerify = `enviroment check: ${domainCheck}`;
    this.debug && this.srvConsole.print('info', mssgVerify);
  }

  getDomain() {
    // Obtener el dominio completo de la url
    this.domainInfo = this.srvDomain.getDomainInfo();
    let messageInfo = `Domain URL: ${this.domainInfo}`;
    this.debug && this.srvConsole.print('info', messageInfo);
  }

  getCoreExt() {
    // Obtener unicamente el dominio actual
    let domainCore = this.srvDomain.getDomainCore();
    let domainExt = this.srvDomain.getDomainExt();
    this.domCoreExt = `${domainCore}.${domainExt}`;
    let messageCore = `Core Ext: ${this.domCoreExt}`;
    this.debug && this.srvConsole.print('info', messageCore);
  }

  getUrlHref() {
    let urlDomain = this.domainInfo;
    let urlBase = this.srvBaseUrl.getBaseUrl();
    if (urlBase === '/') { urlBase = ''; }
    this.urlFront = urlDomain + urlBase;
    let message = `URL Front: ${this.urlFront}`;
    this.debug && this.srvConsole.print('info', message);
  }
}
