<div *ngIf="isLoading" class="loading-init">
  <div class="loading-spinner"></div>
</div>

<main class="main-content">
  <section class="pt-8 pb-4"></section>
  <!-- section -->
  <section class="pt-8 pb-4">
    <div class="container mb-lg-2">
      <div class="row">
        <!-- col -->
        <div class="col-6 col-xs-12 col-sm-10 col-md-8 mx-auto text-center">
          <div class="card smooth-shadow-lg bg-white rounded-0">
            <div class="card-body">
              <form [formGroup]="loginForm"
              class="needs-validation"
              novalidate id="formLogin"
              [ngClass]="{'was-validated': formSubmit}">
                <div class="row">
                  <div class="col-12 mb-6">
                    <h2 class="fw_light">
                      <span>Ingreso Sistema</span>
                    </h2>
                  </div>
                  <div class="col-12 mb-3">
                    <label class="form-label" for="cdLogin">
                      <span class="text-dark">Usuario</span>
                      <span class="text-danger">(*)</span>
                    </label>
                    <input type="text"
                    class="form-control custom-input rounded-0"
                    id="cdLogin" name="cdLogin"
                    placeholder="Usuario"
                    formControlName="cdLogin"
                    required="">
                    <div class="invalid-feedback">
                      <span>
                        Campo <b>usuario</b> es obligatorio
                      </span>
                    </div>
                  </div>
                  <div class="col-12 mb-6">
                    <label class="form-label" for="cdPassword">
                      <span class="text-dark">Conteaseña</span>
                      <span class="text-danger">(*)</span>
                    </label>
                    <input type="password"
                    class="form-control custom-input rounded-0"
                    id="cdPassword" name="cdPassword"
                    placeholder="Contraseña"
                    formControlName="cdPassword"
                    required="">
                    <div class="invalid-feedback">
                      <span>
                        Campo <b>contraseña</b> es obligatorio
                      </span>
                    </div>
                  </div>
                  <div class="col-12 mb-3">
                    <div class="row">
                      <div class="col-6 mb-2">
                        <button class="btn btn-md btn-info w-100 rounded-0"
                        type="button" (click)="logionAction()">
                          <span>Loguearme</span>
                        </button>
                      </div>
                      <div class="col-6 mb-2">
                        <button class="btn btn-md btn-info w-100 rounded-0"
                        type="button" (click)="logionAction()">
                          <span>Registrarme</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div class="row">
                <div class="col-12">
                  <div class="mt-4 text-center">
                    <!--Facebook-->
                    <a href="#" class="btn-social btn-social-outline btn-facebook">
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-facebook mb-1" viewBox="0 0 16 16">
                        <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"></path>
                      </svg>
                    </a>
                    <!--Twitter-->
                    <a href="#" class="btn-social btn-social-outline btn-twitter">
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-twitter mb-1" viewBox="0 0 16 16">
                        <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"></path>
                      </svg>
                    </a>
                    <!--LinkedIn-->
                    <a href="#" class="btn-social btn-social-outline btn-linkedin">
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-linkedin mb-1" viewBox="0 0 16 16">
                        <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"></path>
                      </svg>
                    </a>
                    <!--GitHub-->
                    <a href="#" class="btn-social btn-social-outline btn-github">
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-github mb-1" viewBox="0 0 16 16">
                        <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z"></path>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>

<!-- Modal: Servicio Inaccesible -->
<div class="modal fade"
id="modalAlertAvailable"
name="modalAlertAvailable"
tabindex="-1" aria-hidden="true"
aria-labelledby="modalAlertData"
data-bs-backdrop="static"
data-bs-keyboard="false">
  <div class="modal-dialog modal-sm modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content rounded-0">
      <div class="modal-header justify-content-center rounded-0 border-0 bg-danger">
        <h4 class="modal-title" id="modalAlertData">
          <span class="text-white">Servicio Inaccesible</span>
        </h4>
      </div>
      <div class="modal-body text-center">
        <p class="text-danger mb-2">
          <span>
            Estamos experimentando problemas con nuestro servicio.
          </span>
        </p>
        <p class="text-dark mb-2">
          <span>
            Nos encontramos trabajando para resolverlo
            y puedas continuar con tu proceso.
            Por favor, inténtalo nuevamente más tarde.
          </span>
        </p>
      </div>
      <div class="modal-footer justify-content-center">
        <button class="btn btn-md btn-success rounded-0"
        type="button" data-bs-dismiss="modal">
          <span>Aceptar</span>
        </button>
        <button type="button" class="btn btn-md btn-primary rounded-0">
          <span>Soporte Técnico</span>
        </button>
      </div>
    </div>
  </div>
</div>
