import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Environment } from 'src/environments/environment';
import { BaseurlService } from 'src/app/services/functions/baseurl/baseurl.service';
import { DomainService } from 'src/app/services/functions/domain/domain.service';

@Component({
  selector: 'app-wo-ex-portfolio',
  templateUrl: './wo-ex-portfolio.component.html',
  styleUrls: ['./wo-ex-portfolio.component.css']
})
export class WoExPortfolioComponent implements OnInit {
  appTitle: string = Environment.title;
  domainMajor: string = '';
  domainPtcl: string = '';
  urlFront: string = '';

  domainIntg: string = '';
  domainOdoo: string = '';
  domainPex: string = '';
  domainPiis: string = '';
  domainPsx: string = '';

  dtXamppSeven: any[] = [];
  dtXamppEight: any[] = [];
  dtWorkSpace: any[] = [];
  
  constructor (
    private router: Router,
    private serviceBaseUrl: BaseurlService,
    private serviceDomain: DomainService,
  ) {}

  ngOnInit(): void {
    this.getDomain();
    this.getUrlHref();
    //this.getSubDmn();
    this.jsXamppSeven();
    this.jsXamppEight();
    this.jsWorkSpace();
  }

  getDomain() {
    let dmMajor = this.serviceDomain.getDomainInfo();
    this.domainMajor = dmMajor;
  }

  getUrlHref() {
    let urlDomain = this.domainMajor;
    let urlBase = this.serviceBaseUrl.getBaseUrl();
    if (urlBase === '/') { urlBase = ''; }
    this.urlFront = urlDomain + urlBase;
    console.log(`urlFront: ${this.urlFront}`);
  }

  jsXamppSeven() {
    this.dtXamppSeven = [
      {
        'id_register': '1',
        'cd_status': 'active',
        'os_demo': `${this.domainPsx}/prisma_ocrend_alfaparf/`,
        'os_link': 'https://github.com/adisonzenemij/prisma_ocrend_alfaparf',
        'os_privacy': 'Privado',
        'os_repository': 'GitHub',
        'os_title': 'Prisma Ocrend Alfaparf',
      },
      {
        'id_register': '2',
        'cd_status': 'active',
        'os_demo': `${this.domainPsx}/prisma_ocrend_internal/`,
        'os_link': 'https://github.com/adisonzenemij/prisma_ocrend_internal',
        'os_privacy': 'Privado',
        'os_repository': 'GitHub',
        'os_title': 'Prisma Ocrend Internal',
      },
      {
        'id_register': '3',
        'cd_status': 'active',
        'os_demo': `${this.domainPsx}/prisma_ocrend_service/`,
        'os_link': 'https://github.com/adisonzenemij/prisma_ocrend_service',
        'os_privacy': 'Privado',
        'os_repository': 'GitHub',
        'os_title': 'Prisma Ocrend Service',
      },
      {
        'id_register': '4',
        'cd_status': 'active',
        'os_demo': `${this.domainPsx}/prisma_ocrend_webapp/`,
        'os_link': 'https://github.com/adisonzenemij/prisma_ocrend_webapp',
        'os_privacy': 'Privado',
        'os_repository': 'GitHub',
        'os_title': 'Prisma Ocrend Webapp',
      },
      {
        'id_register': '5',
        'cd_status': 'block',
        'os_demo': `${this.domainPsx}/prisma_ocrend_webbck/`,
        'os_link': 'https://github.com/adisonzenemij/prisma_ocrend_webbck',
        'os_privacy': 'Privado',
        'os_repository': 'GitHub',
        'os_title': 'Prisma Ocrend Webbck',
      },
      {
        'id_register': '6',
        'cd_status': 'active',
        'os_demo': `${this.domainPsx}/prisma_ocrend_worked/`,
        'os_link': 'https://github.com/adisonzenemij/prisma_ocrend_worked',
        'os_privacy': 'Privado',
        'os_repository': 'GitHub',
        'os_title': 'Prisma Ocrend Worked',
      },
      {
        'id_register': '7',
        'cd_status': 'active',
        'os_demo': `${this.domainPsx}/staff_ocrend_medimple/`,
        'os_link': 'https://github.com/adisonzenemij/staff_ocrend_medimple',
        'os_privacy': 'Privado',
        'os_repository': 'GitHub',
        'os_title': 'Staff Ocrend Medmiple',
      },
      {
        'id_register': '8',
        'cd_status': 'active',
        'os_demo': `${this.domainPsx}/sena_project_slim/`,
        'os_link': 'https://github.com/adisonzenemij/sena_project_slim',
        'os_privacy': 'Publico',
        'os_repository': 'GitHub',
        'os_title': 'Sena Project Slim',
      },
      {
        'id_register': '9',
        'cd_status': 'active',
        'os_demo': `${this.domainPsx}/staff_slim_seven/`,
        'os_link': 'https://github.com/adisonzenemij/staff_slim_seven',
        'os_privacy': 'Privado',
        'os_repository': 'GitHub',
        'os_title': 'Staff Slim Seven',
      },
    ];
    this.dtXamppSeven.sort((a, b) => parseInt(b.id_register) - parseInt(a.id_register));
    console.log(this.dtXamppSeven);
  }

  jsXamppEight() {
    this.dtXamppEight = [
      {
        'id_register': '1',
        'cd_status': 'active',
        'os_demo': `${this.domainPex}/staff_laravel_medimple/`,
        'os_link': 'https://github.com/adisonzenemij/staff_laravel_medimple',
        'os_privacy': 'Publico',
        'os_repository': 'GitHub',
        'os_title': 'Staff Laravel Medimple',
      },
      {
        'id_register': '2',
        'cd_status': 'active',
        'os_demo': `${this.domainPex}/staff_laravel_transmi/`,
        'os_link': 'https://github.com/adisonzenemij/staff_laravel_transmi',
        'os_privacy': 'Privado',
        'os_repository': 'GitHub',
        'os_title': 'Staff Laravel Transmi',
      },
      {
        'id_register': '3',
        'cd_status': 'active',
        'os_demo': `${this.domainPex}/staff_slim_eight/`,
        'os_link': 'https://github.com/adisonzenemij/staff_slim_eight',
        'os_privacy': 'Privado',
        'os_repository': 'GitHub',
        'os_title': 'Staff Slim Eight',
      },
      {
        'id_register': '4',
        'cd_status': 'block',
        'os_demo': `${this.domainPex}/staff_laravel_project/`,
        'os_link': 'https://github.com/adisonzenemij/staff_laravel_project',
        'os_privacy': 'Publico',
        'os_repository': 'GitHub',
        'os_title': 'Staff Laravel Project',
      },
    ];
    this.dtXamppEight.sort((a, b) => parseInt(b.id_register) - parseInt(a.id_register));
    console.log(this.dtXamppEight);
  }

  jsWorkSpace() {
    this.dtWorkSpace = [
      {
        'id_register': '1',
        'cd_status': 'active',
        'os_demo': `${this.domainPiis}/sena_project_angular/`,
        'os_link': 'https://github.com/adisonzenemij/sena_project_angular',
        'os_privacy': 'Publico',
        'os_repository': 'GitHub',
        'os_title': 'Sena Project Angular',
      },
    ];
    this.dtWorkSpace.sort((a, b) => parseInt(b.id_register) - parseInt(a.id_register));
    console.log(this.dtWorkSpace);
  }
}
