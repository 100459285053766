<!--<app-ge-ex-navbar></app-ge-ex-navbar>-->

<main>
  <section class="pt-8 pb-4"></section>
  <!-- section -->
  <section class="pt-8 pb-4">
    <div class="container mb-lg-2">
      <div class="row">
        <!-- col -->
        <div class="col-12 mx-auto text-center">
          <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
            <input type="hidden" name="cmd" value="_s-xclick" />
            <input type="hidden" name="hosted_button_id" value="7SXFGFG9N72BC" />
            <input type="hidden" name="currency_code" value="USD" />
            <input type="image" src="https://www.paypalobjects.com/es_XC/i/btn/btn_buynowCC_LG.gif"
            border="0" name="submit" title="PayPal es una forma segura y fácil de pagar en línea." alt="Comprar Ahora" />
          </form>
        </div>
      </div>
    </div>
  </section>
</main>

<!--<app-ge-ex-footer></app-ge-ex-footer>-->
