import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  // Obtener datos del almacenamiento local
  localGet(name: any): any {
    return localStorage.getItem(
      name
    );
  }

  // Adicionar datos en el almacenamiento local
  localSetJson(name: any, data: any) {
    let result = JSON.stringify(data);
    localStorage.setItem(
      name, result
    );
  }

  // Adicionar datos en el almacenamiento local
  localSetNot(name: any, data: any) {
    localStorage.setItem(
      name, data
    );
  }

  // Remover datos del almacenamiento local
  localRemove(name: any) {
    localStorage.removeItem(
      name
    );
  }

  // Obtener datos del almacenamiento de sesión
  sessionGet(name: any) {
    return sessionStorage.getItem(
      name
    );
  }

  // Adicionar datos en el almacenamiento de sesión
  sessionSetJson(name: any, data: any) {
    let result = JSON.stringify(data);
    sessionStorage.setItem(
      name, result
    );
  }

  // Adicionar datos en el almacenamiento de sesión
  sessionSetNot(name: any, data: any) {
    sessionStorage.setItem(
      name, data
    );
  }

  // Remover datos del almacenamiento de sesión
  sessionRemove(name: any)  {
    sessionStorage.removeItem(
      name
    );
  }
}
