<nav class="navbar navbar-expand-lg fixed-top nbCustom"
[class.scrolled]="isScrolled" [class.initial]="!isScrolled">
  <div class="container container_fluid px-0">
    <div class="d-flex">
      <a class="navbar-brand" href="external/landing">
        <img src="{{ urlFront }}/assets/apps/img/svg/logo/logo-blue.svg"
        alt="Adison Jimenez" class="img-fluid" width="115" height="20" />
        <!--<span>{{ title }}</span>-->
      </a>
      <!--<div class="dropdown d-none d-md-block">
        <button type="button" id="menuMain"
        class="btn btn-light-primary text-primary"
        data-bs-toggle="dropdown" aria-expanded="false">
          <i class="fe fe-list me-2 align-middle"></i>
          <span>Contribucion</span>
        </button>
        <ul class="dropdown-menu" aria-labelledby="menuMain">
          <li>
            <a href="external/landing"
            class="dropdown-item">
              <span>Pagina Principal</span>
            </a>
          </li>
          <li>
            <a href="https://www.paypal.com/donate/?hosted_button_id=ACV7LKW4SWRNJ"
            class="dropdown-item">
              <span>Donacion</span>
            </a>
          </li>
          <li class="d-none">
            <a href="external/login"
            class="dropdown-item">
              <span>Iniciar Sesion</span>
            </a>
          </li>
          <li class="d-none">
            <a href="external/register"
            class="dropdown-item">
              <span>Regitrarme</span>
            </a>
          </li>
        </ul>
      </div>-->
    </div>
    <div class="order-lg-3">
      <div class="d-flex align-items-center">
        <div class="dropdown d-none">
          <button type="button" id="themeData" name="themeData"
          class="btn btn-light btn-icon rounded-circle d-flex align-items-center"
          aria-expanded="false" data-bs-toggle="dropdown"
          aria-label="Toggle theme (auto)">
            <i class="bi theme-icon-active"></i>
            <span class="visually-hidden bs-theme-text">Toggle theme</span>
          </button>
          <ul class="dropdown-menu dropdown-menu-end shadow" aria-labelledby="bs-theme-text">
            <li>
              <button type="button" class="dropdown-item d-flex align-items-center" data-bs-theme-value="light" aria-pressed="false">
                <i class="bi theme-icon bi-sun-fill"></i>
                <span class="ms-2 d-none">Light</span>
                <span class="ms-2">Claro</span>
              </button>
            </li>
            <li>
              <button type="button" class="dropdown-item d-flex align-items-center" data-bs-theme-value="dark" aria-pressed="false">
                <i class="bi theme-icon bi-moon-stars-fill"></i>
                <span class="ms-2 d-none">Dark</span>
                <span class="ms-2">Oscuro</span>
              </button>
            </li>
            <li>
              <button type="button" class="dropdown-item d-flex align-items-center active" data-bs-theme-value="auto" aria-pressed="true">
                <i class="bi theme-icon bi-circle-half"></i>
                <span class="ms-2 d-none">Auto</span>
                <span class="ms-2">Automatico</span>
              </button>
            </li>
          </ul>
        </div>
        <!--<a href="#" class="btn btn-icon btn-light rounded-circle d-md-inline-flex ms-2">
          <i class="fe fe-shopping-cart align-middle"></i>
        </a>-->
        <!--<a href="external/login"
        class="btn btn-primary ms-2 d-none d-lg-block">
          <span>Iniciar Sesion</span>
        </a>-->
        <!--<a href="external/register"
        class="btn btn-primary ms-2 d-none d-lg-block">
          <span>Registrarme</span>
        </a>-->
        <!-- Button -->
        <button
          class="navbar-toggler collapsed ms-2 ms-lg-0"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbar-default"
          aria-controls="navbar-default"
          aria-expanded="false"
          aria-label="Toggle navigation">
          <span class="icon-bar top-bar mt-0"></span>
          <span class="icon-bar middle-bar"></span>
          <span class="icon-bar bottom-bar"></span>
        </button>
      </div>
    </div>

    <!-- Collapse -->
    <div class="collapse navbar-collapse" id="navbar-default">
      <ul class="navbar-nav mx-auto">
        <li class="nav-item">
          <a class="nav-link" href="external/landing">
            <span>Inicio</span>
          </a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarAbout"
          data-bs-toggle="dropdown" aria-haspopup="true"
          aria-expanded="false" data-bs-display="static">
            <span>Acerca De</span>
          </a>
          <ul class="dropdown-menu dropdown-menu-arrow" aria-labelledby="navbarAbout">
            <li>
              <a class="dropdown-item"
              href="external/study">
                <span>Estudios</span>
              </a>
            </li>
            <li>
              <a class="dropdown-item"
              href="external/portfolio">
                <span>Portafolio</span>
              </a>
            </li>
            <li>
              <a class="dropdown-item"
              href="external/contact">
                <span>Contacto</span>
              </a>
            </li>
          </ul>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarApplication"
          data-bs-toggle="dropdown" aria-haspopup="true"
          aria-expanded="false" data-bs-display="static">
            <span>Aplicaciones</span>
          </a>
          <ul class="dropdown-menu dropdown-menu-arrow" aria-labelledby="navbarApplication">
            <li class="d-none">
              <a class="dropdown-item cursorPointer"
              (click)="getSubDmn('odoo', '');">
                <span>Odoo</span>
              </a>
            </li>
            <li>
              <a class="dropdown-item cursorPointer"
              (click)="getSubDmn('xsp', '');">
                <span>Xampp 7</span>
              </a>
            </li>
            <li>
              <a class="dropdown-item cursorPointer"
              (click)="getSubDmn('xep', '');">
                <span>Xampp 8</span>
              </a>
            </li>
            <li>
              <a class="dropdown-item cursorPointer"
              (click)="getSubDmn('iisp', '');">
                <span>IIS</span>
              </a>
            </li>
          </ul>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarIntegration"
          data-bs-toggle="dropdown" aria-haspopup="true"
          aria-expanded="false" data-bs-display="static">
            <span>Integraciones</span>
          </a>
          <ul class="dropdown-menu dropdown-menu-arrow" aria-labelledby="navbarIntegration">
            <li>
              <a class="dropdown-item cursorPointer"
              (click)="getSubDmn('intg', 'datatable');">
                <span>Datatable</span>
              </a>
            </li>
            <li>
              <a class="dropdown-item cursorPointer"
              (click)="getSubDmn('intg', 'arcgis');">
                <span>ArcGIS</span>
              </a>
            </li>
            <li>
              <a class="dropdown-item cursorPointer"
              (click)="getSubDmn('intg', 'highcharts');">
                <span>HighCharts</span>
              </a>
            </li>
          </ul>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarSupport"
          data-bs-toggle="dropdown" aria-haspopup="true"
          aria-expanded="false" data-bs-display="static">
            <span>Contribuciones</span>
          </a>
          <ul class="dropdown-menu dropdown-menu-arrow" aria-labelledby="navbarSupport">
            <li>
              <a class="dropdown-item" target="_blank" rel="noopener"
              href="https://www.paypal.com/donate/?hosted_button_id=ACV7LKW4SWRNJ">
                <span>Donacion</span>
              </a>
            </li>
          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link cursorPointer"
          (click)="getSubDmn('blogger', '');">
            <span>Blog</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>
