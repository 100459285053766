<aside class="docs-nav-sidebar">
  <div class="py-5"></div>
  <div class="docs-nav" data-simplebar>
    <!-- Profesional -->
    <div class="card card-hover rounded-0 mb-4 bg-white">
      <div class="card-body">
        <div class="text-center">
          <img src="{{ urlFront }}/assets/apps/img/png/general/profile-adison.png"
          class="rounded-circle avatar-xxl avatar-border mb-3" alt="avatar">
          <h4 class="mb-0">Adison Jimenez</h4>
          <p class="mb-0 fs-6">Desarrollador de Software</p>
          <p class="mb-0 fs-6">Full Stack Intermedio</p>
        </div>
        <div class="d-flex justify-content-between border-bottom py-2 mt-4">
          <span class="text-dark">Carreras Cursados</span>
          <span class="text-info">2</span>
        </div>
        <div class="d-flex justify-content-between border-bottom py-2">
          <span class="text-dark">Experiencia Laboral</span>
          <span class="text-info">7 Años</span>
        </div>
      </div>
    </div>
    <nav class="navbar navbar-expand-md">
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav flex-column" id="sidebarnav">

        </ul>
      </div>
    </nav>
  </div>
  <div class="nav-footer">
    <p class="mb-0 text-dark">
      <span>Desarollado Por</span>
    </p>
    <p class="mb-0">
      <a href="{{ domainMajor }}" target="_blank" class="text-info">
        <span>{{ appTitle }}</span>
      </a>
    </p>
  </div>
</aside>
