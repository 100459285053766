const domainEngSoft = 'https://dsx.engsoft.app';
const domainAdisonNet = 'https://dsx.adisonjimenez.net';
const domainAdisonWork = 'https://dsx.adisonjimenez.work';
const domainLocalhost = 'http://127.0.0.1:7123';

export const Environment = {
  production: true,
  api: {
    java: {
      spring: {
        app: `${domainEngSoft}/adison_domain_spring`,
        net: `${domainAdisonNet}/adison_domain_spring`,
        test: `${domainLocalhost}`,
        work: `${domainAdisonWork}/adison_domain_spring`,
      },
    },
    php: {
      laravel: {
        app: `${domainEngSoft}/adison_domain_laravel`,
        net: `${domainAdisonNet}/adison_domain_laravel`,
        test: `${domainLocalhost}/adison_domain_laravel`,
        work: `${domainAdisonWork}/adison_domain_laravel`,
      },
      slim: {
        app: `${domainEngSoft}/adison_domain_slim`,
        net: `${domainAdisonNet}/adison_domain_slim`,
        test: `${domainLocalhost}/adison_domain_slim`,
        work: `${domainAdisonWork}/adison_domain_slim`,
      },
    },
  },
  tmpl: {
    smart: {
      app: `${domainEngSoft}/version.4.5.1/html-full/dist`,
      net: `${domainAdisonNet}/version.4.5.1/html-full/dist`,
      test: `${domainLocalhost}/version.4.5.1/html-full/dist`,
      work: `${domainAdisonWork}/version.4.5.1/html-full/dist`,
    },
  },
  strg: {
    auth: 'ajAppAuth',
  },
  title: 'Adison Jimenez',
}
