import { Injectable } from '@angular/core';

const alertColor: Record<
  'alert-danger' |
  'alert-info' |
  'alert-success' |
  'alert-warning',
  string
> = {
  'alert-danger': '#FF4E48',
  'alert-info': '#48E6FF',
  'alert-success': '#85FF48',
  'alert-warning': '#FFD048',
};

@Injectable({
  providedIn: 'root'
})
export class ConsoleService {
  private title: string = '¡ Adison Jimenez - Code Debugging !';

  constructor() { }

  error(message: any): void {
    console.log(
      `%c${this.title}`,
      `color: red;
       font-weight: bold;`
    );
    this.bootstrap(
      `Error: ${message}`,
      'alert-danger'
    );
  }

  info(message: any): void {
    console.log(
      `%c${this.title}`,
      `color: red;
       font-weight: bold;`
    );
    this.bootstrap(
      `Info: ${message}`,
      'alert-info'
    );
  }

  sccs(message: any): void {
    console.log(
      `%c${this.title}`,
      `color: red;
       font-weight: bold;`
    );
    this.bootstrap(
      `Success: ${message}`,
      'alert-success'
    );
  }

  warn(message: any): void {
    console.log(
      `%c${this.title}`,
      `color: red;
       font-weight: bold;`
    );
    this.bootstrap(
      `Warning: ${message}`,
      'alert-warning'
    );
  }

  private bootstrap(
    message: string,
    alertClass:
      'alert-danger' |
      'alert-info' |
      'alert-success' |
      'alert-warning'
  ): void {
    // Obtener el color basado en la clase de alerta
    const color = alertColor[alertClass] || '#6c757d';

    // Ajustar valor según necesidades
    const padding = ' '.repeat(100);

    // Imprimir el mensaje en la consola
    console.log(
      `%c${message}` + ` ${padding}`, `
        background-color: ${color};
        padding: 5px;
        border-radius: 0px;
        box-sizing: border-box;
        display: block;
      `
    );
  }
}
