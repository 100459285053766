const domainLocalhost = 'http://127.0.0.1:8305';
const domainEngSoft = 'https://dsx.engsoft.app';
const domainEngTest = 'https://dsx.engtest.app';
const domainAdisonNet = 'https://dsx.adisonjimenez.net';
const domainAdisonWork = 'https://dsx.adisonjimenez.work';

export const Environment = {
  production: false,
  api: {
    java: {
      spring: {
        host: `${domainLocalhost}/portal_platform_spring`,
        app: `${domainEngSoft}/portal_platform_spring`,
        net: `${domainAdisonNet}/portal_platform_spring`,
        test: `${domainEngTest}/portal_platform_spring`,
        work: `${domainAdisonWork}/portal_platform_spring`,
      },
    },
    php: {
      laravel: {
        host: `${domainLocalhost}/portal_platform_laravel`,
        app: `${domainEngSoft}/portal_platform_laravel`,
        net: `${domainAdisonNet}/portal_platform_laravel`,
        test: `${domainEngTest}/portal_platform_laravel`,
        work: `${domainAdisonWork}/portal_platform_laravel`,
      },
      slim: {
        host: `${domainLocalhost}/portal_platform_slim`,
        app: `${domainEngSoft}/portal_platform_slim`,
        net: `${domainAdisonNet}/portal_platform_slim`,
        test: `${domainEngTest}/portal_platform_slim`,
        work: `${domainAdisonWork}/portal_platform_slim`,
      },
    },
  },
  tmpl: {
    smart: {
      host: `${domainLocalhost}/version.4.5.1/html-full/dist`,
      app: `${domainEngSoft}/version.4.5.1/html-full/dist`,
      net: `${domainAdisonNet}/version.4.5.1/html-full/dist`,
      test: `${domainEngTest}/version.4.5.1/html-full/dist`,
      work: `${domainAdisonWork}/version.4.5.1/html-full/dist`,
    },
  },
  // Aplicación
  app: {
    debug: true,
    title: 'Adison Jimenez',
  },
  // Almacenamiento
  strg: {
    auth: 'ppAppAuth',
  },
};
