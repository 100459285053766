import { Component, HostListener, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { BaseurlService } from 'src/app/services/functions/baseurl/baseurl.service';
import { ConsoleService } from 'src/app/services/functions/console/console.service';
import { DomainService } from 'src/app/services/functions/domain/domain.service';
import { Environment } from 'src/environments/environment';

@Component({
  selector: 'app-ge-ex-navbar',
  templateUrl: './ge-ex-navbar.component.html',
  styleUrls: ['./ge-ex-navbar.component.css']
})
export class GeExNavbarComponent implements OnInit {
  env: any = Environment;
  debug: string = this.env.app.debug;
  title: string = this.env.app.title;

  domain: string = '';
  fullurl: string = '';
  urlFront: string = '';

  isScrolled = false;
  darkTheme = true;

  constructor (
    public renderer: Renderer2,
    public router: Router,
    public srvBaseUrl: BaseurlService,
    public srvConsole: ConsoleService,
    public srvDomain: DomainService,
  ) {}

  ngOnInit(): void {
    this.getDomain();
    this.getUrlHref();
  }

  getDomain() {
    this.domain = this.srvDomain.getDomainCore();
    this.fullurl = this.srvDomain.getDomainInfo();
  }

  getUrlHref() {
    let urlDomain = this.fullurl;
    let urlBase = this.srvBaseUrl.getBaseUrl();
    if (urlBase === '/') { urlBase = ''; }
    this.urlFront = urlDomain + urlBase;
  }

  getSubDmn(dataSub: any, endpoint: any) {
    let urlSub = this.srvDomain.getSubOther(
      dataSub, endpoint
    );
    console.log('urlSub', urlSub);
    window.open(urlSub, '_blank');
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    this.isScrolled = scrollPosition > 0;
    const htmlElement = document.querySelector('html');

    if (htmlElement) {
      if (this.isScrolled) {
        this.renderer.addClass(document.body, 'scrolled');
        if (htmlElement.getAttribute('data-bs-theme') === 'dark') {
          this.renderer.addClass(document.body, 'dark-theme');
        }
      } else {
        this.renderer.removeClass(document.body, 'scrolled');
        this.renderer.removeClass(document.body, 'dark-theme');
      }
    }
  }
}
