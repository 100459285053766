import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { BaseurlService } from 'src/app/services/functions/baseurl/baseurl.service';
import { DomainService } from 'src/app/services/functions/domain/domain.service';
import { ConsoleService } from 'src/app/services/functions/console/console.service';
import { Environment } from 'src/environments/environment';

@Component({
  selector: 'app-wo-external',
  templateUrl: './wo-external.component.html',
  styleUrls: ['./wo-external.component.css']
})
export class WoExternalComponent implements OnInit {
  env: any = Environment;
  debug: string = this.env.app.debug;
  title: string = this.env.app.title;

  domainInfo: string = '';
  domCoreExt: string = '';
  domainPtcl: string = '';
  urlFront: string = '';

  codeLink: string[] = [];
  codeScript: string[] = [];
  geeksData: any[] = [];
  geeksJson: string = 'assets/geeks/json';
  geeksFile: string = 'data.json';
  geeksUrl: string = '';

  constructor (
    public renderer: Renderer2,
    public router: Router,
    public srvBaseUrl: BaseurlService,
    public srvConsole: ConsoleService,
    public srvDomain: DomainService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.setComponent();
    this.getDomEnv();
    this.getDomain();
    this.getCoreExt();
    this.getUrlHref();

    this.urlGeeks();
    await this.jsonGeeks();
    this.idxHeadFilter();
    this.idxHeadLink();
    this.idxScriptFilter();
    this.idxScriptLink();
  }

  setComponent() {
    // Obtener el nombre del componente automaticamente
    let information = this.constructor.name;
    let finalData = information.replace('Component', '');
    let message = `Current Component: ${finalData}`;
    this.debug && this.srvConsole.print('warn', message);
  }

  getDomEnv() {
    let domainCheck = this.srvDomain.getDomainVerify();
    let mssgVerify = `enviroment check: ${domainCheck}`;
    this.debug && this.srvConsole.print('info', mssgVerify);
  }

  getDomain() {
    // Obtener el dominio completo de la url
    this.domainInfo = this.srvDomain.getDomainInfo();
    let messageInfo = `Domain URL: ${this.domainInfo}`;
    this.debug && this.srvConsole.print('info', messageInfo);
  }

  getCoreExt() {
    // Obtener unicamente el dominio actual
    let domainCore = this.srvDomain.getDomainCore();
    let domainExt = this.srvDomain.getDomainExt();
    this.domCoreExt = `${domainCore}.${domainExt}`;
    let messageCore = `Core Ext: ${this.domCoreExt}`;
    this.debug && this.srvConsole.print('info', messageCore);
  }

  getUrlHref() {
    let urlDomain = this.domainInfo;
    let urlBase = this.srvBaseUrl.getBaseUrl();
    if (urlBase === '/') { urlBase = ''; }
    this.urlFront = urlDomain + urlBase;
    let message = `URL Front: ${this.urlFront}`;
    this.debug && this.srvConsole.print('info', message);
  }

  urlGeeks() {
    this.geeksUrl = `${this.geeksJson}/${this.geeksFile}`;
  }

  async jsonGeeks() {
    let urlData = `${this.urlFront}/${this.geeksUrl}`;
    const dataFetch = await this.dataFetch(urlData);
    if (dataFetch && dataFetch.length > 0) {
      console.log('dataFetch', dataFetch);
      this.geeksData = dataFetch;
    }
  }

  async dataFetch(url: any) {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(
          `HTTP error! status: ${response.status}`
        );
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching the JSON data:', error);
      return null;
    }
  }

  idxHeadFilter() {
    this.codeLink.push('s4iwr4THepL9radROr4M');
  }

  idxHeadLink() {
    this.geeksData.filter(
      item => item.type === 'link' &&
      this.codeLink.includes(item.code)
    ).forEach(item => {
      const linkElement = this.renderer.createElement('link');
      this.renderer.setAttribute(linkElement, 'rel', 'stylesheet');
      this.renderer.setAttribute(linkElement, 'href', item.link);
      this.renderer.appendChild(document.head, linkElement);
    });
  }

  idxScriptFilter() {
    this.codeScript.push('wlWL1RaSop2yejesoqeb');
    this.codeScript.push('M9TL1e2rLwagiFrE4h3j');
    this.codeScript.push('weteTuB1uprewravEQuB');
    this.codeScript.push('vewrewr127guxUyaZlxA');
  }

  idxScriptLink() {
    this.geeksData.filter(
      item => item.type === 'script' &&
      this.codeScript.includes(item.code)
    ).forEach(item => {
      const scriptElement = this.renderer.createElement('script');
      this.renderer.setAttribute(scriptElement, 'src', item.link);
      this.renderer.appendChild(document.body, scriptElement);
    });
  }
}
