import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';

import { Environment } from 'src/environments/environment';
import { BaseurlService } from 'src/app/services/functions/baseurl/baseurl.service';
import { DomainService } from 'src/app/services/functions/domain/domain.service';

@Component({
  selector: 'app-wo-technology',
  templateUrl: './wo-technology.component.html',
  styleUrls: ['./wo-technology.component.css']
})
export class WoTechnologyComponent implements OnInit {
  appTitle: string = Environment.title;
  domainMajor: string = '';
  urlFront: string = '';

  codeLink: string[] = [];
  codeScript: string[] = [];
  geeksData: any[] = [];
  geeksJson: string = 'assets/geeks/json';
  geeksFile: string = 'data.json';
  geeksUrl: string = '';

  constructor (
    private renderer: Renderer2,
    private router: Router,
    private serviceBaseUrl: BaseurlService,
    private serviceDomain: DomainService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.getDomain();
    this.getUrlHref();
    //await this.tmplGeeks();
  }

  getDomain() {
    let dmMajor = this.serviceDomain.getDomainInfo();
    this.domainMajor = dmMajor;
  }

  getUrlHref() {
    let urlDomain = this.domainMajor;
    let urlBase = this.serviceBaseUrl.getBaseUrl();
    if (urlBase === '/') { urlBase = ''; }
    this.urlFront = urlDomain + urlBase;
    //console.log(`urlFront: ${this.urlFront}`);
  }

  async tmplGeeks() {
    this.urlGeeks();
    await this.jsonGeeks();
    this.idxHeadFilter();
    this.idxHeadLink();
    this.idxScriptFilter();
    this.idxScriptLink();
  }

  urlGeeks() {
    this.geeksUrl = `${this.geeksJson}/${this.geeksFile}`;
  }

  async jsonGeeks() {
    let urlData = `${this.urlFront}/${this.geeksUrl}`;
    const dataFetch = await this.dataFetch(urlData);
    if (dataFetch && dataFetch.length > 0) {
      console.log('dataFetch', dataFetch);
      this.geeksData = dataFetch;
    }
  }

  async dataFetch(url: any) {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(
          `HTTP error! status: ${response.status}`
        );
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching the JSON data:', error);
      return null;
    }
  }

  idxHeadFilter() {
    this.codeLink.push('5HI8o45JobiJu0riQlFl');
    this.codeLink.push('t5e8u6eglgIp1aStIdip');
  }

  idxHeadLink() {
    this.geeksData.filter(
      item => item.type === 'link' &&
      this.codeLink.includes(item.code)
    ).forEach(item => {
      const linkElement = this.renderer.createElement('link');
      this.renderer.setAttribute(linkElement, 'rel', 'stylesheet');
      this.renderer.setAttribute(linkElement, 'href', item.link);
      this.renderer.appendChild(document.head, linkElement);
    });
  }

  idxScriptFilter() {
    this.codeScript.push('wrlduvLzL3asoqupRAbl');
    this.codeScript.push('PH0CrLsPuga9i1isTuSW');
    this.codeScript.push('chotr0s8O5OHUSP5prIm');
    this.codeScript.push('gifaylz1V4qIgltRUthi');
    this.codeScript.push('p9ljLvIyAw5haq7q9zaY');
    this.codeScript.push('DR87WOwl5LSwl3iPaC4e');
    this.codeScript.push('52b4wrEqUtRawra0IsW6');
    this.codeScript.push('tAslpitRAZimibE6owre');
    this.codeScript.push('2Rat2iTih1wisWUt1UrI');
    this.codeScript.push('tru7rlfri2lwAqixophi');
    this.codeScript.push('pesTecif8quzasOspi3l');
    this.codeScript.push('s3A00aDORekEtrenEjIP');
    this.codeScript.push('Q0phaTREsposAf3y42rE');
    this.codeScript.push('f8usP4mEk1Chub6iziWr');
    this.codeScript.push('spublw2lQEchiqudrlhU');
  }

  idxScriptLink() {
    this.geeksData.filter(
      item => item.type === 'script' &&
      this.codeScript.includes(item.code)
    ).forEach(item => {
      const scriptElement = this.renderer.createElement('script');
      this.renderer.setAttribute(scriptElement, 'src', item.link);
      this.renderer.appendChild(document.body, scriptElement);
    });
  }
}
